/* Font families  */
export const font_family = {
    fm_1: '"Segoe UI Symbol"',
    fm_2: "1.75rem",
};

/* Responsive font sizes */
export const font = {
    size: {
        fs_1: "2.75rem",
        fs_2: "1.75rem",
        fs_3: "1.3rem",
        fs_4: "0.9rem",
        fs_5: "0.75rem",
        fs_6: "0.6rem",
        fs_sm: '0.4rem',
        fs_xs: '0.3rem',
    },
    family: { fm_1: "Helvetica", fm_2: "Helvetica", fm_3: "1.75rem" },
    // family: { fm_1: '"Segoe UI Symbol"',fm_2:'Montserrat, sans-serif', fm_3: "1.75rem" },
};
