// import { useSearchParams } from 'react-router-dom';
// import ProjectsContainer from './ProjectsContainer';
// import ActiveProjectContainer from './RequirementsContainer';


// export default function ProjectsLayout() {
//     const [searchParams, setSearchParams] = useSearchParams();
//     const searchQuery = searchParams.get("name");

//     console.log(searchQuery, "query")


//     if (searchQuery) {
//         return <ActiveProjectContainer />
//     } else {
//         return <ProjectsContainer />
//     }
// }



