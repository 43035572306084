
import { MAIN_URL, REQUEST_URL } from "../../constant/ApplicationVariables";
import{ axiosInstance } from "../useAxios/useAxios";

// const axiosInstance = axiosInstance;

export const getConversationHistoryList_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.aiChatConversation ,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const saveGeneratedResponse_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.aiChatSave ,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const generateResponse_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.aiChatGenerate ,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}