import { Box, Button, CircularProgress, IconButton, InputAdornment, Tab, Tabs, TextField, useTheme } from '@mui/material'
import React, { useRef, useState } from 'react'
import ApplicationNavigation from './ApplicationNavigation'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { useApplicationContext } from '../../context/ApplicationContext';
import { ArrowRightAlt } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import LogoImage from "../../assets/logo.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { ModulePath } from '../../constant/ApplicationVariables';

function ApplicationHeader() {
    const { activePage, setActivePage, headerSearchText, setHeaderSearchText, profileDetails } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname?.split("/")[1];
    const navigate = useNavigate();
    const searchRef = useRef(null);
    let timer;

    const [isLoading, setIsLoading] = useState(false);

    const handleClickSearch = () => {
        console.log(searchRef.current?.value, "searchRef")
        setHeaderSearchText(searchRef.current.value);
    }

    const handleChangeSearch = (e) => {
        clearTimeout(timer);
        setIsLoading(true);

        timer = setTimeout(() => {
            setHeaderSearchText(e.target.value);
            setIsLoading(false);
        }, 1000);
    }

    console.log(headerSearchText, "headerSearchText");

    return (
        <Box className="h-full flex flex-col gap-4 px-4 py-4" bgcolor={grey[300]}>

            {/* upper container */}
            <Box className="h-full flex items-center  justify-between gap-4">

                {/* logo container */}
                <Box className="h-full flex items-center w-40 rounded-xl " bgcolor={grey[200]} onClick={() => navigate(ModulePath.projects)}>
                    <img src={LogoImage} alt="logo" />
                </Box>

                {/* pages */}
                {pathName === "requirements" && <Box className=" w-fit">
                    <Tabs
                        value={ModulePath.requirements}
                        onChange={() => { }}
                        textColor="secondary"
                        indicatorColor="secondary"
                        variant="scrollable"
                        scrollButtons={false}
                    // sx={{
                    //     '& .MuiTabs-indicator': {
                    //         display: 'flex',
                    //         justifyContent: 'center',
                    //         // backgroundColor: 'transparent',
                    //     },
                    //     '& .MuiTabs-indicatorSpan': {
                    //         // maxWidth: 40,
                    //         width: '100%',
                    //         backgroundColor: '#635ee7',
                    //     }
                    // }}
                    >
                        <Tab label="Requirement" value={ModulePath.requirements} onClick={() => navigate(ModulePath.requirements)} className="w-fit" />
                        <Tab label="Use Case" value={ModulePath.useCase} />
                        <Tab label="Test Case" value={ModulePath.testCase} />
                        <Tab label="Planning" value={ModulePath.planning} />
                        <Tab label="Execution" value={ModulePath.execution} />
                        <Tab label="Dashboard" value={ModulePath.dashboard} />
                    </Tabs>
                </Box>}

                {/* search container */}
                <Box className="h-full flex-1 flex justify-end ">

                    <Box className="h-full w-full flex min-w-56 max-w-[34vw] rounded-full overflow-hidden  " border={`1px solid ${grey[400]}`}>
                        <Box className="flex items-center justify-center flex-1">
                            <TextField
                                ref={searchRef}
                                fullWidth
                                placeholder="Search"
                                // slotProps={{
                                //     input: {
                                //         endAdornment: <InputAdornment position="start">
                                //             <ArrowRightAlt />
                                //         </InputAdornment>,
                                //     },
                                // }}
                                sx={{
                                    px: "4px",
                                    '& .MuiInputBase-input': {
                                        boxSizing: 'border-box',
                                        border: "none",
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                // value={headerSearchText}
                                onChange={handleChangeSearch}
                            />
                        </Box>

                        {isLoading && <Box className="flex items-center justify-center px-4 cursor-pointer ">
                            {/* <ArrowRightAlt /> */}
                            <CircularProgress size={22} sx={{ color: theme.palette.dark.main }} />
                        </Box>}
                    </Box>

                </Box>

                {/* user container */}
                <Box className="  flex items-center  justify-end">
                    <IconButton size="small">
                        <AccountCircleOutlinedIcon />
                    </IconButton>
                    <IconButton size="small">
                        <SettingsOutlinedIcon />
                    </IconButton>
                </Box>
            </Box>

            {/* lower container */}
            {/* <Box className="h-fit flex items-center justify-between gap-4 ">

                <Box className="flex-1 flex justify-between   max-w-[700px] mx-auto">
                    <ApplicationNavigation />
                    <Button variant="contained" size="small" startIcon={<AutoAwesomeOutlinedIcon />} onClick={handleToggleChatWindow} className="rounded-full" sx={{ borderRadius: "50px" }}>Ask AI</Button>
                </Box>


            </Box> */}
        </Box>
    )
}

export default ApplicationHeader
