import { Description, Folder, FolderOutlined, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { Tree } from 'react-arborist'
import { font } from '../../styles/font';
import { useApplicationContext } from '../../context/ApplicationContext';
import AutoSizer from 'react-virtualized-auto-sizer';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecialOutlined';
import { LightTooltip } from '../styled/CustomComponent';
import { FolderType } from '../../constant/ApplicationVariables';

export default function Treeview({ dataList }) {
    console.log(dataList, "dataList");

    return (
        <Box className="flex flex-1 px-4 w-full ">
            <AutoSizer>
                {({ height, width }) => {
                    console.log(height, width, "heightwidth")
                    return (
                        <Tree
                            initialData={dataList}
                            openByDefault={true}
                            width={width}
                            height={height}
                            indent={24}
                            rowHeight={38}
                        // overscanCount={1}
                        // paddingTop={30}
                        // paddingBottom={10}
                        // padding={25 /* sets both */}
                        >
                            {Node}
                        </Tree>

                    )
                }}
            </AutoSizer>
        </Box>
    )
}


function Node({ node, style, dragHandle }) {
    const { currentFolderData, setCurrentFolderData, isUpdateCurrentFolderData, setIsUpdateCurrentFolderData, activeProject , isUpdateFolderStructure, setIsUpdateFolderStructure } = useApplicationContext();
    const theme = useTheme();


    const handleFolderClick = (folder) => {
        folder.toggle();
        setCurrentFolderData(folder);

        console.log(folder, "node1data");
    }

    useEffect(() => {
        if (node?.level === 0 && (!currentFolderData || currentFolderData?.data?.name !== activeProject?.name) ) {
            setCurrentFolderData(node);
        }
    }, []);

    useEffect(() => {
        if (!isUpdateFolderStructure && node?.data?.type === FolderType.project) {
            setCurrentFolderData(node)
        }
    }, [isUpdateFolderStructure, setCurrentFolderData])

    // To update the current folder  data
    useEffect(() => {
        if (isUpdateCurrentFolderData && node?.id === currentFolderData?.id) {
            setCurrentFolderData(node);
            setIsUpdateCurrentFolderData(false);
        }
    }, [isUpdateCurrentFolderData, setCurrentFolderData, setIsUpdateCurrentFolderData])

    console.log(node, "node1")

    return (
        <Box
            className={`flex items-center gap-1 rounded-xl py-1 cursor-pointer`}
            style={style}
            ref={dragHandle}
            onClick={() => { handleFolderClick(node) }}
            sx={{
                color: (currentFolderData?.id === node?.id) && theme.palette.common.white,
                // background: (currentFolderData?.id === node?.id) && "rgba(0, 0, 0, 0.05)" 
                background: (currentFolderData?.id === node?.id) && theme.palette.secondary.light
            }}
        >

            {!node.isLeaf &&
                (node.isOpen ?
                    <KeyboardArrowDown /> : <KeyboardArrowRight />
                )
            }

            {node.data.type === "project" && <FolderSpecialIcon sx={{ fontSize: font.size.fs_3 }} />}
            {node.data.type === "module" && <FolderOutlined  sx={{ fontSize: font.size.fs_3 }} />}

            <LightTooltip title={node.data.name}>
                <Typography fontSize={font.size.fs_5} className="line-clamp-1">{node.data.name}</Typography>
            </LightTooltip>
        </Box>
    );
}