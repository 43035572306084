import { ArrowRightAlt, AutoAwesomeOutlined, Close, Delete, Edit, FileOpenOutlined, KeyboardArrowDownOutlined, PlayCircleOutline, Save } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Checkbox, Chip, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useApplicationContext } from "../../context/ApplicationContext";
import { useEffect, useState } from "react";
import { font } from "../../styles/font";
import { blue, brown, green, grey, red } from "@mui/material/colors";
import { CustomAccordionComponent, CustomAccordionComponentDetails, CustomAccordionComponentSummary, LightTooltip } from "../styled/CustomComponent";

class STORY_STATUS {
    static DRAFT = "Draft";
    static APPROVED = "Approved";
    static UNDER_DEVELOPMENT = "Under development";
    static CLOSED = "Closed";
}

function StoryPreview() {
    const { currentUserStory, setCurrentUserStory, openStoryPreview, setOpenStoryPreview, toggleChatWindow, setToggleChatWindow } = useApplicationContext();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const theme = useTheme();

    const [tabValue, setTabValue] = useState(0);
    const [isGeneratedContent, setIsGeneratedContent] = useState(false);
    const [isEditStory, setIsEditStory] = useState(false);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCloseStory = (story) => {
        setCurrentUserStory(null);
        setOpenStoryPreview(false);
    }

    const handleEditStory = () => {
        setIsEditStory(!isEditStory)
    }

    return (
        <Box className="grow flex justify-center border absolute left-0 top-0 right-0 bottom-0 rounded-lg  ">
            {/* wrapper container */}
            <Box className="flex flex-col gap-4 grow w-full h-full  p-4 pt-0 overflow-y-auto" bgcolor={theme.palette.primary.layout_col}>

                {/* header */}
                <Box className="flex flex-col border-b sticky top-0 py-2 gap-2 backdrop-blur-sm z-50 bg-blue-500" bgcolor={theme.palette.primary.layout_col}>
                    {/* top section */}
                    <Box className="flex items-center gap-4 w-full" >
                        <Box className="flex flex-1 items-center justify-between  py-2" >
                            {(isGeneratedContent || isEditStory) && tabValue === 0 ?
                                <TextField size="small" fullWidth defaultValue={currentUserStory?.name} multiline maxRows={2} />
                                :
                                <Typography fontSize={font.size.fs_3} className="px-4">{currentUserStory?.name}</Typography>
                            }
                        </Box>

                        <IconButton size="small" onClick={handleCloseStory}>
                            <Close />
                        </IconButton>
                    </Box>

                    {/* bottom section */}
                    <Box className="flex items-center gap-4  justify-between w-full" >

                        <Box className="w-fit border rounded-lg px-4">
                            <Tabs
                                value={tabValue}
                                onChange={handleChangeTab}
                            // textColor="secondary"
                            // indicatorColor="secondary" 
                            // aria-label="secondary tabs example"
                            >
                                <Tab value={0} label="Details" />
                                <Tab disabled={isGeneratedContent || isEditStory} value={1} label="Scenarios" />
                            </Tabs>

                        </Box>

                        {tabValue === 0 && !isGeneratedContent && <Box className="flex items-center gap-4 ">
                            <Button variant="contained" color="secondary" size="small" startIcon={<Edit />} onClick={handleEditStory}>Edit</Button>
                            <Button variant="contained" color="error" size="small" startIcon={<Delete />}>Delete</Button>
                        </Box>}

                        {tabValue === 0 && isGeneratedContent && <Box className="flex items-center gap-4 ">
                            <Button variant="contained" size="small" startIcon={<Save />} onClick={() => { }}>Save</Button>
                        </Box>}

                        {tabValue === 1 && <Box className="flex items-center ">
                            {/* <LightTooltip arrow placement="bottom" title="Elements">
                                <IconButton size="small">
                                    <FileOpenOutlined />
                                </IconButton>
                            </LightTooltip>
                            <LightTooltip arrow placement="bottom" title="Applicaiton">
                                <IconButton size="small">
                                    <FileOpenOutlined />
                                </IconButton>
                            </LightTooltip>
                            <LightTooltip arrow placement="bottom" title="Testdata">
                                <IconButton size="small">
                                    <FileOpenOutlined />
                                </IconButton>
                            </LightTooltip> */}
                            <Button size="small" variant="contained" startIcon={<AutoAwesomeOutlined />} >Generate Scenarios</Button>
                        </Box>}

                    </Box>
                </Box>


                {/* main container */}
                <Box className="flex flex-1 h-full ">
                    {tabValue === 0 && <UserStoryComponent isEditStory={isEditStory} isGeneratedContent={isGeneratedContent} />}
                    {tabValue === 1 && <ScenarioComponent />}
                </Box>

            </Box>
        </Box>
    )
}

export default StoryPreview


function KeyValue({ label, value }) {
    return (
        <Box className="flex items-center gap-2 w-full max-w-[32%] ">
            <LabelTypography label={label} />
            <Box className="flex-1">{value}</Box>
        </Box>
    )
}

function LabelTypography(props) {
    return <Typography className="flex-1" fontSize={font.size.fs_5} {...props}>{props?.label}:</Typography>
}

function UserStoryComponent({ item, isEditStory, isGeneratedContent }) {
    const { currentUserStory } = useApplicationContext();
    const theme = useTheme();

    const [toggleChatWindow, setToggleChatWindow] = useState(false);

    const description = {
        "given": `A sales representative needs to upload a signed contract for a customer.`,
        "when": `The representative selects the associated contact or opportunity, names the document, selects "Contract" as the document type, and uploads the file.`,
        "then": `The system saves the document, linking it to the appropriate contact or opportunity, and auto-generates the upload date.`
    }

    const [stageType, setStageType] = useState(STORY_STATUS.DRAFT);

    const handleChange = (event) => {
        setStageType(event.target.value);
    };

    function GetStoryStatusColor(story_status) {
        // console.log(story_status, "story_status")
        switch (story_status?.toLowerCase()) {
            case STORY_STATUS.DRAFT?.toLowerCase():
                return brown[500];
            case STORY_STATUS.APPROVED?.toLowerCase():
                return green[500];
            case STORY_STATUS.UNDER_DEVELOPMENT?.toLowerCase():
                return blue[500];
            case STORY_STATUS.CLOSED?.toLowerCase():
                return red[500];
            default:
                return grey[500];
        }
    }

    useEffect(() => {
        setStageType(currentUserStory?.status)
    }, [currentUserStory?.status])

    return (
        <Box className=" flex flex-col gap-4 w-full rounded-lg" >

            {(!isEditStory && !isGeneratedContent) && <Box className="flex flex-wrap  px-4">
                <KeyValue label={"Id"} value={<Typography fontSize={font.size.fs_5} fontWeight="bold">#{currentUserStory.id}</Typography>} />
                <KeyValue label={"Type"} value={<Typography fontSize={font.size.fs_5} fontWeight="bold" className="capitalize">{currentUserStory.type}</Typography>} />
                {/* <KeyValue label={"Stage"} value={<Chip variant="outlined" label={currentUserStory?.status} sx={{ fontSize: font.size.fs_6,  height: "fit-content", py: "2px", color: GetStoryStatusColor(currentUserStory?.status?.toLowerCase()) }}>{currentUserStory?.status}</Chip>} /> */}
                <KeyValue label={"Stage"} value={
                    <Select
                        value={stageType}
                        onChange={handleChange}

                        sx={{
                            background: GetStoryStatusColor(stageType?.toLowerCase()),
                            color: theme.palette.common.white,
                            fontSize: font.size.fs_6,
                            height: "32px",
                            borderRadius: "1rem",
                            border: "none",
                            // py: "2px",
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "transparent",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "transparent",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused": {
                                borderColor: "transparent",
                            },
                            "& .MuiMenuItem-root.Mui-selected": {
                                borderColor: "transparent",
                                backgroundColor: GetStoryStatusColor(currentUserStory?.status?.toLowerCase()),
                            },
                            "& .MuiSvgIcon-root": {
                                fill: theme.palette.common.white,
                            },
                            "& .MuiSvgIcon-root:hover": {
                                fill: theme.palette.common.white,
                            },
                        }}
                    >
                        <MenuItem value={STORY_STATUS.DRAFT}>{STORY_STATUS.DRAFT}</MenuItem>
                        <MenuItem value={STORY_STATUS.APPROVED}>{STORY_STATUS.APPROVED}</MenuItem>
                        <MenuItem value={STORY_STATUS.UNDER_DEVELOPMENT}>{STORY_STATUS.UNDER_DEVELOPMENT}</MenuItem>
                        <MenuItem value={STORY_STATUS.CLOSED}>{STORY_STATUS.CLOSED}</MenuItem>
                    </Select>} />
                <KeyValue label={"Priority"} value={<Typography fontSize={font.size.fs_5} fontWeight="bold">Medium</Typography>} />
                <KeyValue label={"Attribute"} value={<Typography fontSize={font.size.fs_5} fontWeight="bold">Attribute Value</Typography>} />
            </Box>}

            {(!isEditStory && !isGeneratedContent) && <Box className="flex flex-col gap-2">
                <Typography fontSize={font.size.fs_4}>Activity:</Typography>
                <Box className="flex items-center px-4">
                    <KeyValue label={"Updated by"} value={<Typography fontSize={font.size.fs_5} fontWeight="bold" sx={{ display: "flex", alignItems: "center", gap: 1 }}> <Avatar sx={{ width: "27px", height: "27px", fontSize: font.size.fs_4, background: theme.palette.primary.main }}>A</Avatar> Alex</Typography>} />
                    <KeyValue label={"Created at"} value={<Typography fontSize={font.size.fs_5} fontWeight="bold">12 November 2024 11:00AM</Typography>} />
                    <KeyValue label={"Updated at"} value={<Typography fontSize={font.size.fs_5} fontWeight="bold">13 November 2024 02:00AM</Typography>} />
                </Box>
            </Box>}

            <Box className="flex flex-col gap-2">
                <Typography fontSize={font.size.fs_4}>Description:</Typography>

                <Box className="flex flex-col gap-2 px-4">
                    <Box className="flex flex-col gap-1">

                        <LabelTypography label={"As a user"} />
                        {(isGeneratedContent || isEditStory) ? <TextField size="small" value={description?.given} multiline maxRows={4} />
                            : <Typography variant="body2" className="font-bold">{currentUserStory?.description?.given}</Typography>}
                    </Box>
                    <Box className="flex flex-col gap-1">
                        <LabelTypography label={"I want to be able to"} />
                        {(isGeneratedContent || isEditStory) ? <TextField size="small" value={description?.when} multiline maxRows={4} />
                            : <Typography variant="body2" className="font-bold">{currentUserStory?.description?.when}</Typography>}
                    </Box>
                    <Box className="flex flex-col gap-1">
                        <LabelTypography label={"To achieve this"} />
                        {(isGeneratedContent || isEditStory) ? <TextField size="small" value={description?.then} multiline maxRows={4} />
                            : <Typography variant="body2" className="font-bold">{currentUserStory?.description?.then}</Typography>}
                    </Box>
                </Box>
            </Box>

            <Box className="flex flex-col gap-2">
                <Typography fontSize={font.size.fs_4}>Acceptance Criteria:</Typography>
                <Box className={`flex flex-col px-4  ${isGeneratedContent || isEditStory ? "" : "gap-1"} `}>
                    {currentUserStory?.acceptanceCriteria?.map((criteria, index) => (
                        <Box key={index} className="flex items-center ">
                            {(isGeneratedContent || isEditStory) ?
                                <Checkbox size="small" />
                                :
                                <ArrowRightAlt />}
                            <Typography variant="body2" >{criteria}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>


        </Box>
    )
}

function ScenarioComponent({ item }) {
    const { currentUserStory } = useApplicationContext();
    const theme = useTheme();

    const [toggleChatWindow, setToggleChatWindow] = useState(false);
    const [expandedSteps, setExpandedSteps] = useState([]);


    // "Navigate to the login page.",
    // "Click on the login button.",
    // "Enter the username.",
    // "Enter the password.",
    // "Click on the continue button.",

    const dataList = [
        {
            id: 1,
            name: "Navigate to the login page",
            testCase: "Navigate to the login page",
            scenarios: [
                {
                    scenario: "Valid URL is entered in the browser",
                    expected: "Login page is displayed",
                    stepDef: "driver.get('validURL')"
                },
                {
                    scenario: "Invalid URL is entered in the browser",
                    expected: "Error message is displayed",
                    stepDef: "driver.get('invalidURL')"
                },
                {
                    scenario: "No URL is entered in the browser",
                    expected: "Error message is displayed",
                    stepDef: "driver.get('')"
                },
            ]
        },
        {
            id: 2,
            name: "Click on the login button",
            testCase: "Click on the login button",
            scenarios: [
                {
                    scenario: "Valid username and password are entered",
                    expected: "Dashboard page is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('validUsername'); driver.findElement(By.id('password')).sendKeys('validPassword'); driver.findElement(By.id('loginButton')).click()"
                },
                {
                    scenario: "Invalid username and password are entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('invalidUsername'); driver.findElement(By.id('password')).sendKeys('invalidPassword'); driver.findElement(By.id('loginButton')).click()"
                },
                {
                    scenario: "No username and password are entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('loginButton')).click()"
                },
                {
                    scenario: "Only username is entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('validUsername'); driver.findElement(By.id('loginButton')).click()"
                },
                {
                    scenario: "Only password is entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('validPassword'); driver.findElement(By.id('loginButton')).click()"
                },
            ]
        },
        {
            id: 3,
            name: "Enter the username",
            testCase: "Enter the username",
            scenarios: [
                {
                    scenario: "Valid username is entered",
                    expected: "Username is accepted",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('validUsername')"
                },
                {
                    scenario: "Invalid username is entered (special characters, numbers)",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('invalidUsername!123')"
                },
                {
                    scenario: "Username is left blank",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('')"
                },
                {
                    scenario: "Username is too long",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('averyverylongusername')"
                },
                {
                    scenario: "Username is too short",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('a')"
                },
            ]
        },
        {
            id: 4,
            name: "Enter the password",
            testCase: "Enter the password",
            scenarios: [
                {
                    scenario: "Valid password is entered",
                    expected: "Password is accepted",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('validPassword')"
                },
                {
                    scenario: "Invalid password is entered (special characters, numbers)",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('invalidPassword!123')"
                },
                {
                    scenario: "Password is left blank",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('')"
                },
                {
                    scenario: "Password is too long",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('averyverylongpassword')"
                },
                {
                    scenario: "Password is too short",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('short')"
                },
            ]
        },
        {
            id: 5,
            name: "Click on the continue button",
            testCase: "Click on the continue button",
            scenarios: [
                {
                    scenario: "Valid username and password are entered",
                    expected: "Dashboard page is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('validUsername'); driver.findElement(By.id('password')).sendKeys('validPassword'); driver.findElement(By.id('continueButton')).click()"
                },
                {
                    scenario: "Invalid username and password are entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('invalidUsername'); driver.findElement(By.id('password')).sendKeys('invalidPassword'); driver.findElement(By.id('continueButton')).click()"
                },
                {
                    scenario: "No username and password are entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('continueButton')).click()"
                },
                {
                    scenario: "Only username is entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('validUsername'); driver.findElement(By.id('continueButton')).click()"
                },
                {
                    scenario: "Only password is entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('validPassword'); driver.findElement(By.id('continueButton')).click()"
                },
            ]
        }
    ]



    return (
        <Box className=" flex flex-col gap-2 h-full w-full rounded-xl " >


            {
                dataList?.map((item, index) => (
                    <CustomAccordionComponent key={index} expanded={expandedSteps.includes(item.id)} >
                        <CustomAccordionComponentSummary>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", width: "100%" }}>
                                <Box className="flex items-center gap-1 flex-1">
                                    <IconButton
                                        sx={{ color: expandedSteps.includes(item.id) && "white", background: expandedSteps.includes(item.id) && theme.palette.primary.main, "&:hover": { background: expandedSteps.includes(item.id) && theme.palette.primary.main }, borderRadius: "20px" }}
                                        size="small"
                                        onClick={() => { setExpandedSteps(expandedSteps.includes(item.id) ? expandedSteps.filter(id => id !== item.id) : [...expandedSteps, item.id]) }}
                                    >
                                        <KeyboardArrowDownOutlined />
                                    </IconButton>
                                    <Box className="flex flex-col gap-2 flex-1 ">
                                        <Typography fontSize={font.size.fs_5} className=" ">{item.name}</Typography>
                                    </Box>
                                </Box>

                                <Box className="flex items-center">
                                    <LightTooltip title={`Total Scenarios : ${item?.scenarios?.length}`} arrow placement="top">
                                        <Avatar sx={{ width: "22px", height: "22px", fontSize: font.size.fs_5, color: "inherit", background: "white", mr: "6px", border: `2px solid ${grey[600]}` }} >{item?.scenarios?.length} </Avatar>
                                    </LightTooltip>
                                    <LightTooltip arrow placement="top" title="Execute Step">
                                        <IconButton size="small" sx={{ borderRadius: "20px" }}>
                                            <PlayCircleOutline />
                                        </IconButton>
                                    </LightTooltip>
                                    <LightTooltip arrow placement="top" title="Generate scenarios using AI">
                                        <IconButton size="small" sx={{ borderRadius: "20px" }}>
                                            <AutoAwesomeOutlined />
                                        </IconButton>
                                    </LightTooltip>
                                </Box>
                            </Box>
                        </CustomAccordionComponentSummary>
                        <CustomAccordionComponentDetails>
                            <Box className="flex flex-col gap-2">
                                {
                                    item?.scenarios?.map((item, index) => (
                                        <Box key={index} className="flex items-center gap-2 px-2 py-1 border rounded-lg">
                                            <Box className="flex flex-col flex-1">
                                                <LightTooltip arrow placement="top" title={item.scenario}>
                                                    <Typography fontSize={font.size.fs_5} className="w-fit">{item.scenario}</Typography>
                                                </LightTooltip>
                                                <LightTooltip arrow placement="top" title={item.stepDef}>
                                                    <Typography fontSize={font.size.fs_5} color="primary" className="w-fit">{item.stepDef}</Typography>
                                                </LightTooltip>
                                            </Box>

                                            <LightTooltip arrow placement="top" title="Execute Scenario">
                                                <IconButton size="small" >
                                                    <PlayCircleOutline />
                                                </IconButton>
                                            </LightTooltip>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </CustomAccordionComponentDetails>
                    </CustomAccordionComponent>
                ))
            }

        </Box>
    )
}