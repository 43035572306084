import { AccountCircleOutlined, ArrowRightAlt, Delete, DeleteTwoTone, Edit, FolderOutlined, MoreHoriz, MoreVert, PlayArrow } from "@mui/icons-material"
import { Box, Chip, IconButton, Menu, MenuItem, Popover, Typography, useTheme } from "@mui/material"
import { font } from "../../styles/font"
import { blue, brown, green, grey, red } from "@mui/material/colors";
import { useState } from "react";
import { LightTooltip } from "../styled/CustomComponent";

class STORY_STATUS {
    static DRAFT = "Draft";
    static APPROVED = "Approved";
    static UNDER_DEVELOPMENT = "Under development";
    static CLOSED = "Closed";
}

const ITEM_HEIGHT = 48;


function ProjectCard({ item, handleClick, handleEditClick,handleClickDelete }) {
    const theme = useTheme();

    const options = [
        {
            label: "Edit Story",
            icon: <Edit sx={{ color: theme.palette.secondary.main }} />,
            onClick: () => console.log("Edit Story"),
        },
        {
            label: "Delete Story",
            icon: <Delete color="error" />,
            onClick: () => console.log("Delete Story"),
        },
    ];

    const [moreAnchorEl, setMoreAchorEl] = useState(null);

    const handleOpenMore = (event) => {
        setMoreAchorEl(event.currentTarget);
    };
    const handleCloseMore = () => {
        setMoreAchorEl(null);
    };

    return (
        <Box className=" flex flex-col justify-between gap-2 w-full h-full  cursor-pointer py-4"  >

            {/* upper container */}
            <Box className="flex flex-col gap-0 px-4">
                <Typography fontSize={font.size.fs_4} className=" line-clamp-3 ">{item?.name}</Typography>
                <Typography fontSize={font.size.fs_5} className=" line-clamp-2 opacity-80">{item?.description}</Typography>
            </Box>

            {/* bottom container */}
            <Box className="flex items-center justify-between  px-4  " >
                <ItemCount label={"Module"} value={6} />
                <ItemCount label={"User Stories"} value={10} />
            </Box>

            <Box className="flex items-center justify-between gap-4 px-4" >

                <LightTooltip title={<Typography fontSize={font.size.fs_6} className=" ">{item?.categoryName} / {item?.subCategoryName}</Typography>}>
                    <Box className="line-clamp-3">
                        <Typography fontSize={font.size.fs_6} className=" ">{item?.categoryName} /</Typography>
                        <Typography fontSize={font.size.fs_6} className=" opacity-80 ">{item?.subCategoryName}</Typography>
                    </Box>
                </LightTooltip>


                <Box className="flex-1 flex items-center justify-end gap-2  " >
                    <LightTooltip title="Edit">
                        <Edit sx={{ color: blue[500] }} onClick={handleEditClick} />
                    </LightTooltip>

                    <LightTooltip title="Delete">
                        <Delete sx={{ color: red[500] }} onClick={handleClickDelete} />
                    </LightTooltip>

                    <LightTooltip title="Execute">
                        <PlayArrow sx={{ color: brown[500] }} />
                    </LightTooltip>


                    <LightTooltip title="Open">
                        <ArrowRightAlt onClick={handleClick} />
                    </LightTooltip>
                </Box>
            </Box>


            <Popover
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={moreAnchorEl}
                open={Boolean(moreAnchorEl)}
                onClose={handleCloseMore}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={handleCloseMore} className="flex items-center gap-2">
                        {option?.icon}
                        {option?.label}
                    </MenuItem>
                ))}
            </Popover>
        </Box>
    )
}

export default ProjectCard


function ItemCount({ label, value }) {
    return (
        <Box className="flex flex-col">
            <Typography fontSize={font.size.fs_6} className=" opacity-80 ">{label}</Typography>
            <Typography fontSize={font.size.fs_4} className="  ">{value}</Typography>
        </Box>
    )
}