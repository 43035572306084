import axios, { HttpStatusCode } from "axios";
import { MAIN_URL } from "../../constant/ApplicationVariables";

// export default function useAxios() {
  // Creating a new Axios instance with baseURL set to MAIN_URL.url
  export let axiosInstance = axios.create({
    baseURL: MAIN_URL.url,
  });
  
  let token = localStorage.getItem("access-token");


  // Request interceptor: Modify or handle requests before they are sent
  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.contentType === "formData") {
        config = contentTypeReq(config);
      }
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );


  const contentTypeReq = async (config) => {
    // Handling formData content type transformation
    if (config.contentType === "formData") {
      let body = config.data;
      let keys = Object.keys(body);
      let formData = new FormData();
      keys.forEach((key) => {
        formData.append(key, body[key]);
      });

      config.data = formData; // Setting config.data to FormData object
    }

    return config; // Returning modified or original config object
  };

  // Response interceptor: Handle responses or errors from the server


//   return axiosInstance; // Returning the configured Axios instance
// }
