import { Attachment, AutoAwesome, AutoAwesomeOutlined } from "@mui/icons-material"
import { Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import { amber } from "@mui/material/colors"
import { forwardRef, useMemo, useState } from "react"
import { TableVirtuoso } from "react-virtuoso"
import ViewUserStoryDrawer from "../drawer/ViewUserStory"
import { useApplicationContext } from "../../context/ApplicationContext"
import { font } from "../../styles/font"
import { LightTooltip } from "../styled/CustomComponent"

const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ px: "10rem" }} />,
}

export default function UserStoryTable({ dataList, }) {
    const { currentUserStoryData, setCurrentUserStoryData, openEditStoryDialog, setOpenEditStoryDialog, userStoryList, setUserStoryList, selectedUserStoryList, setSelectedUserStoryList , globalLoader, setGlobalLoader } = useApplicationContext();
    const theme = useTheme();

    const [openUserStoryDrawer, setOpenUserStoryDrawer] = useState(false);

    let storiesList = [
        {
            name: "Create a new user profile",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "User can create a new user profile with required information.",
                "User should not be able to create a new user profile if the required information is not provided.",
                "User should not be able to create a new user profile if the email address already exists."
            ]
        },
        {
            name: "Create a new user profile",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "User can create a new user profile with required information.",
                "User should not be able to create a new user profile if the required information is not provided.",
                "User should not be able to create a new user profile if the email address already exists."
            ]
        },
        {
            name: "Create a new user profile",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                "The user should not be able to create a new user profile if the required information is not provided.",
                "The user should not be able to create a new user profile if the email address already exists."
            ]
        },

        {
            name: "Create a new user profile",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                "The user should not be able to create a new user profile if the required information is not provided.",
                "The user should not be able to create a new user profile if the email address already exists."
            ]
        },
        {
            name: "Log in to the service",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 2,
            status: "Approved",
            acceptanceCriteria: [
                "The user should be able to log in to the service with the email address and password.",
                "The user should not be able to log in to the service if the email address or password is incorrect.",
                "The user should not be able to log in to the service if the email address does not exist."
            ]
        },
        {
            name: "Reset password",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 3,
            status: "Under development",
            acceptanceCriteria: [
                "The user should be able to reset the password with the email address.",
                "The user should not be able to reset the password if the email address does not exist."
            ]
        },
        {
            name: "Create a new project",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 6,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new project with the required information such as name and description.",
                "The user should not be able to create a new project if the required information is not provided.",
                "The user should not be able to create a new project if the project name already exists."
            ]
        },
        {
            name: "Edit a project",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 7,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to edit the project with the required information such as name and description.",
                "The user should not be able to edit the project if the required information is not provided.",
                "The user should not be able to edit the project if the project name already exists."
            ]
        },
        {
            name: "Upload contract",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 8,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to upload a signed contract for a customer.",
                "The system should save the document, linking it to the appropriate contact or opportunity, and auto-generate the upload date."
            ]
        }
    ];

    const handleOpenUserStory = (item) => {
        setCurrentUserStoryData(item);
        setOpenEditStoryDialog(true);
    }

    const handleCloseUserStory = () => {
        setOpenEditStoryDialog(false);
        setCurrentUserStoryData(null);
    }

    const handleChangeCheckboxAll = (e) => {
        if (e.target.checked) {
            setSelectedUserStoryList(dataList);
        } else {
            setSelectedUserStoryList([]);
        }
    }

    const handleChangeCheckbox = (e, item) => {
        if (e.target.checked) {
            setSelectedUserStoryList([...selectedUserStoryList, item]);
        } else {
            setSelectedUserStoryList(selectedUserStoryList?.filter((story) => story?.id !== item?.id));
        }
    }

    const columns = [
        {
            data: "AI",
            padding: "none",
            align: "center",
            background: theme.palette.common.white,
            width: "40px"
        },
        {
            data: <Checkbox size="small" checked={!globalLoader && selectedUserStoryList?.length === userStoryList?.length} onChange={handleChangeCheckboxAll} />,
            padding: "checkbox",
            align: "center",
            background: theme.palette.common.white,
            width: "40px"
        },
        {
            data: "Link",
            padding: "none",
            align: "center",
            background: theme.palette.common.white,
            width: "70px"
        },
        {
            data: "Define Type",
            padding: "none",
            align: "center",
            background: theme.palette.common.white,
            width: "100px"
        },
        {
            data: "Id",
            padding: "none",
            align: "center",
            background: theme.palette.common.white,
            width: "100px"
        },
        {
            data: "Name",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "180px"
        },
        {
            data: "Description",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "260px"
        },
        {
            data: "Category",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "100px"
        },
        {
            data: "Moscow",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "80px"
        },
        {
            data: "Assigend To",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "120px"
        },
        {
            data: "Acceptance Criteria",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "260px"
        },
        {
            data: "Sub Product",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "100px"
        },
        {
            data: "ReferenceId",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "100px"
        },
        {
            data: "External Party",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "120px"
        },
        {
            data: "Review Status",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "120px"
        },
        {
            data: "Coverage Status",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "120px"
        },
        {
            data: "Type",
            padding: "none",
            align: "center",
            background: theme.palette.common.white,
            width: "80px"
        },
        {
            data: "Created By",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "100px"
        },
        {
            data: "Created At",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "100px"
        },
        {
            data: "Verification Owner",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "140px"
        },
        {
            data: "Verification Method",
            padding: "none",
            align: "left",
            background: theme.palette.common.white,
            width: "150px"
        },
    ];

    return (
        <Box className="w-full h-full flex flex-col">
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto' }}
                data={dataList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background }}>
                                    <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}
                itemContent={(index, storyData) => (
                    <TableRow onClick={() => handleOpenUserStory(storyData)}>
                        {/* <TableCell padding="checkbox" align="center">
                        <Checkbox size="small" />
                    </TableCell>
                    <TableCell padding="none" align="center">
                        <Attachment sx={{ color: amber[700] }} />
                    </TableCell>
                    <TableCell padding="none" align="center" >
                        {user.type}
                    </TableCell>
                    <TableCell padding="none" align="center" >
                        {user.id}
                    </TableCell>
                    <TableCell padding="none">
                        <Typography className="line-clamp-1">
                            {user.name}
                        </Typography>
                    </TableCell>
                    <TableCell padding="none">
                        <Typography className="line-clamp-1">
                            {user.description["given"]}
                        </Typography>
                    </TableCell>
                    <TableCell padding="none" align="center" >
                        {user.status}
                    </TableCell> */}


                        <TableCell padding="none" align="center" sx={{ minWidth: "40px", maxWidth: "40px", position: "sticky", left: 0, background: theme.palette.common.white }} onClick={(e) => e && e.stopPropagation()}>
                            <AutoAwesome color="primary" />
                        </TableCell>
                        <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", }} onClick={(e) => e && e.stopPropagation()}>
                            <Checkbox size="small" checked={selectedUserStoryList?.includes(storyData)} onChange={(e) => handleChangeCheckbox(e, storyData)} />
                        </TableCell>
                        <TableCell padding="none" align="center" sx={{ minWidth: "70px", maxWidth: "70px", }} onClick={(e) => e && e.stopPropagation()}>
                            <Attachment sx={{ color: amber[700] }} />
                        </TableCell>
                        <LightTooltip title={`User Story`}>
                            <TableCell padding="none" align="center" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> User Story</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.customId}>
                            <TableCell padding="none" align="center" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1 font-bold underline cursor-pointer"> #{storyData?.customId}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.text}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "180px", maxWidth: "180px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.text}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.notes}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "260px", maxWidth: "260px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.notes}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.category}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.category}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.moscow}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "80px", maxWidth: "80px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.moscow}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.assignedTo}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.assignedTo}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.acceptanceCriteria} >
                            <TableCell padding="none" align="left" sx={{ minWidth: "260px", maxWidth: "260px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.acceptanceCriteria}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.subProduct}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }} >
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.subProduct}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.referenceId}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.referenceId}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.externalParty}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer" }} >
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.externalParty}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.reviewStatus}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.reviewStatus}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.coverageStatus}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.coverageStatus}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.type}>
                            <TableCell padding="none" align="center" sx={{ minWidth: "80px", maxWidth: "80px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.type}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.createdBy}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.createdBy}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.createdAt}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.createdAt}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.verificationOwner}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "140px", maxWidth: "140px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.verificationOwner}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.verificationMethod}>
                            <TableCell padding="none" align="left" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.verificationMethod}</Typography>
                            </TableCell>
                        </LightTooltip>

                    </TableRow>
                )}
            />


            {openUserStoryDrawer && currentUserStoryData && <ViewUserStoryDrawer open={openUserStoryDrawer} handleClose={handleCloseUserStory} />}
        </Box>
    )
}



