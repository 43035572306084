import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, styled, Tooltip, tooltipClasses } from "@mui/material";
import { grey } from "@mui/material/colors";

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        // color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: 11,
        borderRadius: '7px',
    },
}));

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    border: `1px solid ${grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    "&:before": {
        display: "none",
    },
}))
export const CustomAccordionComponent = (props) => <CustomAccordion disableGutters elevation={0} {...props} />

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    // border: `1px solid ${grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    "&:before": {
        display: "none",
    },
    "& .MuiAccordionSummary-content": {
        margin: 0,
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
        color: theme.palette.primary.main,
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 0,
    },
    "& .MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content": {
        margin: 0,
    },
    "& .MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-expandIconWrapper": {
        color: theme.palette.primary.main,
    },
}));
export const CustomAccordionComponentSummary = (props) => <CustomAccordionSummary disableGutters elevation={0} {...props} />

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    // border: `1px solid ${grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    "&:before": {
        display: "none",
    },
}));
export const CustomAccordionComponentDetails = (props) => <CustomAccordionDetails disableGutters elevation={0} {...props} />

const LoadingButton = styled(Button)(({ theme }) => ({
    // color: theme.palette.primary.contrastText,
    // backgroundColor: theme.palette.primary.main,
    // "&:hover": {
    //     backgroundColor: theme.palette.primary.dark,
    // },
}));
export const CustomLoadingButton = (props) => <LoadingButton disableElevation disabled={props?.loading || props.disabled} {...props} startIcon={props?.loading ? <CircularProgress size={16} color="inherit" /> : props.startIcon} />