import { Description, Home, PlayArrowRounded, Store } from "@mui/icons-material";
import { ModulePath } from "./ApplicationVariables";

export const ApplicationPages = [
    {
        label: "Home",
        path: ModulePath?.home,
        icon: <Home />,
        activeIcon: <Home />,
        onClick: () => { }
    },
    {
        label: "Story Map",
        path: ModulePath?.story,
        icon: <Description />,
        activeIcon: <Description />,
        onClick: () => { }
    },
    {
        label: "Marketplace",
        path: ModulePath?.marketplace,
        icon: <Store />,
        activeIcon: <Store />,
        onClick: () => { }
    },
    {
        label: "Execution",
        path: ModulePath?.execution,
        icon: <PlayArrowRounded />,
        activeIcon: <PlayArrowRounded />,
        onClick: () => { }
    },
];