import { AccountCircle, AccountCircleOutlined, ArrowRightAlt, AttachFileOutlined, AutoAwesome, Close, CopyAll, ReplayOutlined, RestartAltOutlined, Save, SaveAlt, SendOutlined } from "@mui/icons-material"
import { Avatar, Box, Button, Checkbox, Chip, CircularProgress, FormControlLabel, IconButton, LinearProgress, TextField, Typography, useTheme } from "@mui/material"
import { ApplicationContext, useApplicationContext } from "../../context/ApplicationContext";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { blue, grey, purple } from "@mui/material/colors";
import { font } from "../../styles/font";
import { CustomLoadingButton, LightTooltip } from "../styled/CustomComponent";
import { useEffect, useRef, useState } from "react";
import { AI_Entity, AI_Response_SentBy, FolderType, Severity } from "../../constant/ApplicationVariables";
import { generateResponse_API, getConversationHistoryList_API, saveGeneratedResponse_API } from "../../api/controller/AIChatController";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { HttpStatusCode } from "axios";
import { useNavigate } from "react-router-dom";
import { getFolderStructureList_API } from "../../api/controller/RequirementController";
import { getStoryList_API } from "../../api/controller/StoryController";
import dayjs from "dayjs";


function ChatWindow() {
    const { toggleChatWindow, setToggleChatWindow, activeProject, setActiveProject, activeModule, setActiveModule, activeStory, setActiveStory, currentFolderData, setCurrentFolderData, handleSnackbar } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();

    let string = `As a user, I want to sign up with required information such as name, email, password, and phone number so that the system creates a new user profile.`

    const [isLoading, setIsLoading] = useState(false);
    const [isContentGenerationLoading, setIsContentGenerationLoading] = useState(false);
    const [userInput, setUserInput] = useState("");
    const [responseList, setResponseList] = useState([]);
    const conversationResponseContainerRef = useRef(null);

    useEffect(() => {
        if (conversationResponseContainerRef.current && responseList?.length > 0) {
            conversationResponseContainerRef.current.scroll({
                // top: conversationResponseContainerRef.current.scrollHeight,
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [responseList]);


    const responseList1 = [
        {
            id: 1,
            query: "As a user, I want to sign up with required information such as name, email, password, and phone number so that the system creates a new user profile.",
            response: [
                {
                    id: 2,
                    responseType: "model",
                    name: "Create a new user profile",
                    description: `Create a new user profile with name, email, password and phone number`,
                    type: "story",
                    status: "Draft",
                    acceptanceCriteria: [
                        "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                        "The user should not be able to create a new user profile if the required information is not provided.",
                        "The user should not be able to create a new user profile if the email address already exists."
                    ]

                },
                {
                    id: 3,
                    responseType: "model",
                    name: "Create a new user profile",
                    description: `Create a new user profile with name, email, password and phone number`,
                    type: "story",
                    status: "Draft",
                    acceptanceCriteria: [
                        "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                        "The user should not be able to create a new user profile if the required information is not provided.",
                        "The user should not be able to create a new user profile if the email address already exists."
                    ]
                },
                {
                    id: 4,
                    responseType: "model",
                    name: "Create a new user profile",
                    description: `Create a new user profile with name, email, password and phone number`,
                    type: "story",
                    status: "Draft",
                    acceptanceCriteria: [
                        "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                        "The user should not be able to create a new user profile if the required information is not provided.",
                        "The user should not be able to create a new user profile if the email address already exists."
                    ]
                },
                {
                    id: 5,
                    responseType: "model",
                    name: "Create a new user profile",
                    description: `Create a new user profile with name, email, password and phone number`,
                    type: "story",
                    status: "Draft",
                    acceptanceCriteria: [
                        "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                        "The user should not be able to create a new user profile if the required information is not provided.",
                        "The user should not be able to create a new user profile if the email address already exists."
                    ]
                }
            ]
        },
    ]

    const responseList2 = [
        {
            "chatMessageStr": "I want the tool to have user mgmt, should be allowed to share data with client. It should be real time updated. Should also fetch data from GST portal.",
            "chatMessage": null,
            "sentBy": "USER",
            "conversationId": "916a1883335a49c1b4d2f0ad9b236c5f",
            "responseId": 1,
            "createdAt": "2024-12-10T11:41:51"
        },
        {
            "chatMessageStr": "[\n    {\n        \"name\": \"Patient Management System\",\n        \"description\": \"A comprehensive system for managing patient information, including user management and sharing capabilities.\"\n    },\n    {\n        \"name\": \"Telemedicine Platform\",\n        \"description\": \"A real-time video conferencing platform for remote consultations and monitoring.\"\n    },\n    {\n        \"name\": \"Data Integration Module\",\n        \"description\": \"A module for integrating data from various sources, including the GST portal, for accurate patient records.\"\n    },\n    {\n        \"name\": \"Real-Time Patient Tracking\",\n        \"description\": \"A feature for real-time tracking of patients' vital signs and health metrics.\"\n    },\n    {\n        \"name\": \"User Role Management\",\n        \"description\": \"A module for managing user roles and permissions, ensuring secure access to patient data.\"\n    },\n    {\n        \"name\": \"Secure Data Sharing\",\n        \"description\": \"A feature for securely sharing patient data with authorized clients and healthcare professionals.\"\n    },\n    {\n        \"name\": \"GST Portal Integration\",\n        \"description\": \"A module for integrating GST portal data, ensuring compliance and accurate records.\"\n    }\n]",
            "chatMessage": [
                {
                    "id": null,
                    "name": "Patient Management System",
                    "description": "A comprehensive system for managing patient information, including user management and sharing capabilities."
                },
                {
                    "id": null,
                    "name": "Telemedicine Platform",
                    "description": "A real-time video conferencing platform for remote consultations and monitoring."
                },
                {
                    "id": null,
                    "name": "Data Integration Module",
                    "description": "A module for integrating data from various sources, including the GST portal, for accurate patient records."
                },
                {
                    "id": null,
                    "name": "Real-Time Patient Tracking",
                    "description": "A feature for real-time tracking of patients' vital signs and health metrics."
                },
                {
                    "id": null,
                    "name": "User Role Management",
                    "description": "A module for managing user roles and permissions, ensuring secure access to patient data."
                },
                {
                    "id": null,
                    "name": "Secure Data Sharing",
                    "description": "A feature for securely sharing patient data with authorized clients and healthcare professionals."
                },
                {
                    "id": null,
                    "name": "GST Portal Integration",
                    "description": "A module for integrating GST portal data, ensuring compliance and accurate records."
                }
            ],
            "sentBy": "FLOXPAD",
            "conversationId": "916a1883335a49c1b4d2f0ad9b236c5f",
            "responseId": 2,
            "createdAt": "2024-12-10T11:43:33"
        }
    ]

    const handleGetContext = () => {
        let name = "";
        let type = "";
        if (currentFolderData?.data?.type === FolderType.project) {
            name = currentFolderData?.data?.name;
            type = FolderType.project;
        }
        if (currentFolderData?.data?.type === FolderType.module) {
            name = currentFolderData?.data?.name;
            type = FolderType.module;
        }
        // `Module: ${currentFolderData?.data?.name}`

        return (
            <Box className="flex items-center gap-1 ">
                <Typography fontSize={font.size.fs_5} color="secondary" className="capitalize">@{type}:</Typography>
                <Typography fontSize={font.size.fs_5}  >{name}</Typography>
            </Box>
        )
    }

    const handleClose = () => {
        setToggleChatWindow(false);
    }

    const handleKeyDown = (e) => {
        if(e.key === "Enter") {
            handleGenerate();
        }
    }

    const handleGenerate = () => {
        handleGenerateResponse();
    }


    const handleGetConversationList = async (setLoader) => {
        try {
            setLoader(true);

            let body;

            if (currentFolderData?.data?.type === FolderType.project) {
                body = {
                    "projectName": currentFolderData?.data?.name,
                    "entity": AI_Entity.module,
                    "moduleName": null,
                    "userStoryCustomId": null,
                    "usecaseCustomId": null
                }
            }

            if (currentFolderData?.data?.type === FolderType.module) {
                body = {
                    "projectName": currentFolderData?.parent?.data?.name,
                    "entity": AI_Entity.userStory,
                    "moduleName": currentFolderData?.data?.name,
                    "userStoryCustomId": null,
                    "usecaseCustomId": null
                }
            }

            // if(currentFolderData?.data?.type === FolderType.module) {
            //     body = {
            //         "projectName": currentFolderData?.parent?.data?.name,
            //         "entity": AI_Entity.userStory,
            //         "moduleName": currentFolderData?.data?.name,
            //         "userStoryCustomId": null,
            //         "usecaseCustomId": null
            //     }
            // }


            const response = await getConversationHistoryList_API(body);
            console.log(response, "response handleGetConversationList")

            if (response) {
                errorHandler(response, setLoader);
            }

            if (response.status === HttpStatusCode.Ok) {
                let list = [...response.data]
                setResponseList(list?.reverse());
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }

    const handleGenerateResponse = async () => {
        try {
            setIsContentGenerationLoading(true);

            let body;

            if (currentFolderData?.data?.type === FolderType.project) {
                body = {
                    "projectName": currentFolderData?.data?.name,
                    "entity": AI_Entity.module,
                    "moduleName": null,
                    "userStoryCustomId": null,
                    "usecaseCustomId": null,

                    "prompt": userInput,
                }
            }

            if (currentFolderData?.data?.type === FolderType.module) {
                body = {
                    "projectName": currentFolderData?.parent?.data?.name,
                    "entity": AI_Entity.userStory,
                    "moduleName": currentFolderData?.data?.name,
                    "userStoryCustomId": null,
                    "usecaseCustomId": null,

                    "prompt": userInput,
                }
            }

            // if(currentFolderData?.data?.type === FolderType.module) {
            //     body = {
            //         "projectName": currentFolderData?.parent?.data?.name,
            //         "entity": AI_Entity.userStory,
            //         "moduleName": currentFolderData?.data?.name,
            //         "userStoryCustomId": null,
            //         "usecaseCustomId": null
            //     }
            // }


            const response = await generateResponse_API(body);
            console.log(response, "response handleGenerateResponse")

            if (response) {
                errorHandler(response, setIsContentGenerationLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                setUserInput("");
                handleGetConversationList(setIsContentGenerationLoading);
            }

            setTimeout(() => {
                setIsContentGenerationLoading(false)
            }, 1000);


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsContentGenerationLoading(false);
            }, 1000);
        }
    }

    useEffect(() => {
        handleGetConversationList(setIsLoading);
    }, [currentFolderData?.id]);

    return (
        <Box
            className={`w-full h-full flex flex-col pb-4 rounded-lg`}
        // bgcolor={theme.palette.primary.layout_col}
        >

            <Box className="flex items-center gap-4 justify-between px-4 py-2 shadow-md">

                <Box className="flex flex-col items-start gap-1">
                    {/* <Typography fontSize={font.size.fs_6} color="primary" className="uppercase">AI Assistant</Typography> */}
                    <Chip size="small" variant="" label="AI Assistant" color="secondary" sx={{ fontSize: font.size.fs_6, height: "18px" }} />
                    <Typography fontSize={font.size.fs_5} className="flex items-center">{handleGetContext()}</Typography>
                </Box>


                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>

            {/*  response container */}
            <Box className=" flex-1  max-h-[75%] flex flex-col  box-border relative" >

                {
                    isLoading && <Box className="absolute top-0 left-0 z-20 backdrop-blur-sm w-full h-full flex items-center justify-center">
                        <CircularProgress />
                    </Box>
                }

                {
                    responseList?.length === 0 ? (
                        <Box className="flex-1 flex flex-col items-center justify-center ">
                            <Box className=" flex items-center justify-center gap-2 ">
                                <AutoAwesomeOutlinedIcon sx={{ color: purple[500] }} />
                                <Typography fontSize={font.size.fs_3}>AI Assistant</Typography>
                            </Box>
                            <Typography fontSize={font.size.fs_5}>Your {currentFolderData?.data?.type === FolderType.project ? AI_Entity.module : AI_Entity.userStory} assistant !</Typography>
                        </Box>
                    ) :
                        (
                            <Box ref={conversationResponseContainerRef} className="grow flex flex-col-reverse gap-4 px-2 py-4 overflow-y-auto ">
                                {
                                    responseList?.map((item, index) => (
                                        <MessagesContainer key={index} item={item} />
                                    ))
                                }
                            </Box>
                        )
                }

                {/* content generation loader */}
                <Box className="px-5" sx={{ width: '100%' }}>
                    {isContentGenerationLoading && <LinearProgress />}
                </Box>
            </Box>


            {/* message container */}
            <Box className="px-4 flex flex-col items-center w-full">
                <Box className=" rounded-md flex flex-col shadow-md border  w-full" sx={{ border: `1px solid ${theme.palette.primary.main}` }}>
                    <TextField
                        size="small"
                        placeholder={`Generate ${currentFolderData?.data?.type === FolderType.project ? AI_Entity.module : AI_Entity.userStory}`}
                        disabled={isContentGenerationLoading}
                        autoFocus
                        fullWidth
                        multiline
                        // minRows={1}
                        // maxRows={5}
                        minRows={2}
                        maxRows={2}
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        sx={{
                            p: 0,
                            '& .MuiInputBase-input': {
                                boxSizing: 'border-box',
                                border: "none",
                                // color: "white"
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            }
                        }}
                    />
                    <Box className=" flex justify-end py-1 px-2">
                        <IconButton size="small" disabled={userInput || isContentGenerationLoading}  >
                            <AttachFileOutlined />
                        </IconButton>
                        <IconButton size="small" disabled={!userInput || isContentGenerationLoading} onClick={handleGenerate}  >
                            <SendOutlined />
                        </IconButton>
                    </Box>
                </Box>
            </Box>


        </Box>
    )
}

export default ChatWindow


function MessagesContainer({ item }) {
    const theme = useTheme();

    const [selectedResponseList, setSelectedResponseList] = useState([]);

    const handleToggleSelection = (e, data) => {
        if (e.target.checked) {
            setSelectedResponseList([...selectedResponseList, data]);
        } else {
            setSelectedResponseList(selectedResponseList?.filter((item) => item?.id !== data?.id));
        }
    }

    // useEffect(() => {
    //     setSelectedResponseList([...item?.response]);
    // }, [item?.response]);

    return (
        <Box className={`flex flex-col w-full gap-2  `}>

            <Box className={`flex flex-col w-full gap-0 rounded-lg `} >

                <Box className={`flex  w-full gap-2 `}>
                    {item?.sentBy === AI_Response_SentBy.user
                        ? <UserMessageContainer data={item} />
                        : <ResponseMessageContainer data={item} />
                    }
                </Box>

                {/* <Box className={`flex flex-col w-full gap-0 `} >

                    <Box className={`flex justify-between items-center w-full gap-1  pl-2 pr-2 py-2`}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    checked={selectedResponseList?.length === item?.response?.length}
                                    indeterminate={selectedResponseList?.length > 0 && selectedResponseList?.length < item?.response?.length}
                                    onChange={(e) => setSelectedResponseList(e.target.checked ? item?.response : [])}
                                />}

                            label="Select All"
                            className="select-none"
                            sx={{ fontSize: font.size.fs_4 }}
                        />
                        <Avatar sx={{ width: "1.6rem", height: "1.6rem" }}>
                            <Typography fontSize={font.size.fs_4}>{selectedResponseList?.length}</Typography>
                        </Avatar>
                        <Button size="small" variant="outlined" startIcon={<ReplayOutlined />} color="secondary" >Regenerate</Button>
                        <CustomLoadingButton loading={false} size="small" variant="outlined" startIcon={<Save />} color="secondary"   >Save</CustomLoadingButton>
                    </Box>

                    <Box className={`flex flex-col w-full gap-2  `}>
                        {
                            item?.response?.map((response, index) => (
                                <Box key={index} className={`flex flex-col w-full gap-2 relative`} >
                                    <ResponseMessageContainer item={response} selectedResponseList={selectedResponseList} handleToggleSelection={handleToggleSelection} />
                                </Box>
                            ))
                        }
                    </Box>
                </Box> */}
            </Box>

        </Box>
    )
}

// It is generated response container
function ResponseMessageContainer({ data, }) {
    const { setGlobalLoader, setFolderStructureList, handleSnackbar, currentFolderData, isUpdateFolderStructure, setIsUpdateFolderStructure, setUserStoryList } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();

    const [selectedResponseList, setSelectedResponseList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleToggleSelection = (e, data) => {
        if (e.target.checked) {
            setSelectedResponseList([...selectedResponseList, data]);
        } else {
            setSelectedResponseList(selectedResponseList?.filter((item) => item?.id !== data?.id));
        }
    }

    const handleSaveGeneratedResponse = async () => {
        try {
            setIsLoading(true);

            let body = {
                "responseId": data?.responseId,
                "conversationId": data?.conversationId,
                "ids": [...selectedResponseList?.map((item) => item?.id)]
            }

            const response = await saveGeneratedResponse_API(body);
            console.log(response, "response handleSaveGeneratedResponse")

            if (response) {
                errorHandler(response, setIsLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                // setIsUpdateFolderStructure(true);

                if (currentFolderData?.data?.type === FolderType.project) {
                    setIsUpdateFolderStructure(true);
                }
                if (currentFolderData?.data?.type === FolderType.module) {
                    handleGetUserStoryList();
                }
            }

        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }

    const handleGetUserStoryList = async () => {
        try {
            // setGlobalLoader(true);

            let body = {
                "moduleName": currentFolderData?.data?.name,
                "projectName": currentFolderData?.parent?.data?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                // setGlobalLoader(false);
            }, 1000);
        }
    }

    const handleGetFolderStructureList = async (project) => {
        try {
            setGlobalLoader(true);

            let query = `name=${project?.name}`

            const response = await getFolderStructureList_API(query);

            if (response.status === HttpStatusCode.Ok) {
                setFolderStructureList(response.data);
                setSelectedResponseList([]);
            }

            if (response && response.status !== HttpStatusCode.Ok) {
                errorHandler(response);
            }

            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, "projectreponse errorr")
        }
    }

    useEffect(() => {
        setSelectedResponseList([...data?.chatMessage]);
    }, [data?.chatMessage]);

    console.log(selectedResponseList, "selectedResponseList")

    return (
        <Box className={`flex flex-col w-full gap-2 px-2 py-2 rounded-lg`} bgcolor={theme.palette.common.white}>
            <Box className={`flex flex-col w-full gap-2 px-2 py-2 rounded-lg `} bgcolor={theme.palette.common.white}>

                <Box className={`flex justify-between items-center w-full gap-1  pl-2 pr-2 py-0`}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={selectedResponseList?.length === data?.chatMessage?.length}
                                indeterminate={selectedResponseList?.length > 0 && selectedResponseList?.length < data?.chatMessage?.length}
                                onChange={(e) => setSelectedResponseList(e.target.checked ? data?.chatMessage : [])}
                            />}

                        label="Select All"
                        className="select-none"
                        sx={{ fontSize: font.size.fs_4 }}
                    />
                    <Avatar sx={{ width: "1.6rem", height: "1.6rem" }}>
                        <Typography fontSize={font.size.fs_4}>{selectedResponseList?.length}</Typography>
                    </Avatar>
                    <Button size="small" variant="outlined" startIcon={<ReplayOutlined />} color="secondary" >Regenerate</Button>
                    <CustomLoadingButton loading={isLoading} disabled={selectedResponseList?.length === 0} size="small" variant="outlined" startIcon={<Save />} color="secondary" onClick={() => handleSaveGeneratedResponse()}>Save</CustomLoadingButton>
                </Box>


                <Box className={`flex flex-col w-full gap-2  `}>
                    {
                        data?.chatMessage?.map((response, index) => (
                            <Box key={index} className={`flex  w-full gap-2 relative`} >
                                <Box className="flex h-fit">
                                    <Checkbox size="small" checked={selectedResponseList?.includes(response)} onChange={(e) => handleToggleSelection(e, response)} />
                                </Box>

                                <Box className="flex-1 flex flex-col  border  gap-1 rounded-lg shadow-md " sx={{ background: theme.palette.common.white }}>

                                    <ResponseModuleComponent response={response} />

                                </Box>
                            </Box>
                        ))
                    }
                </Box>
            </Box>

            <Box className=" flex" >
                <Typography fontSize={font.size.fs_6}  className="opacity-80">{dayjs(data?.createdAt).format("DD MMM YYYY, hh:mm A")}</Typography>
            </Box>



        </Box>
    )
}



function ResponseMessageContainer1({ item, selectedResponseList, handleToggleSelection }) {
    const { openStoryConfirmationPreview, setOpenStoryConfirmationPreview, currentUserStory, setCurrentUserStory, isContentLoading, setIsContentLoading } = useApplicationContext();
    const theme = useTheme();

    const handleSaveResponse = (item) => {
        setIsContentLoading(true);
        setOpenStoryConfirmationPreview(true);
        setCurrentUserStory(item?.response);

        setTimeout(() => {
            setIsContentLoading(false);
        }, 3000);
    }

    return (
        <Box className="flex gap-1 w-full" >
            <Box className="flex h-fit">
                <Checkbox size="small" checked={selectedResponseList?.includes(item)} onChange={(e) => handleToggleSelection(e, item)} />
            </Box>

            <Box className="flex-1 flex flex-col  border  gap-1 rounded-lg shadow-md " sx={{ background: theme.palette.common.white }}>
                <Box className="flex flex-col gap-0 px-4 py-2">
                    <Typography fontSize={font.size.fs_5}>Title: {item?.name}</Typography>
                    <Typography fontSize={font.size.fs_5}>Description: {item?.description}</Typography>
                </Box>

                {/* <Box className="flex flex-col gap-0 mt-2 px-4">
                    <Typography fontSize={font.size.fs_5}>Acceptance Criteria:</Typography>
                    <Box className="flex flex-col ">
                        {
                            item?.response?.acceptanceCriteria?.map((item, index) => (
                                <Box key={index} className="flex gap-2">
                                    <ArrowRightAlt />
                                    <Typography fontSize={font.size.fs_5}>{item}</Typography>
                                </Box>
                            ))
                        }
                    </Box>
                </Box> */}
            </Box>

            {/* <Box className="flex items-center justify-end gap-2 border-t py-2 px-4" bgcolor={grey[200]}>
                <Button size="small" variant="contained" startIcon={<CopyAll />} color="disabled" sx={{ height: "1.5rem" }}>Copy</Button>
                <Button size="small" variant="contained" startIcon={<ReplayOutlined />} color="secondary" sx={{ height: "1.5rem" }}>Regenerate</Button>
                <CustomLoadingButton loading={isContentLoading} size="small" variant="contained" startIcon={<Save />} sx={{ color: "primary", height: "1.5rem" }} onClick={() => handleSaveResponse(item)}>Save</CustomLoadingButton>
            </Box> */}
        </Box>
    )
}

function UserMessageContainer({ data }) {
    const theme = useTheme();

    return (
        <Box className={`flex flex-col items-end w-full gap-2 `} >

            <Box className={`flex flex-col items-end gap-1 px-4 py-2 rounded-lg backdrop-blur-lg shadow-md`} sx={{ background: theme.palette.secondary.light }}>
                <Box className=" flex" >
                    <Typography fontSize={font.size.fs_5} color={theme.palette.common.white}>{data?.chatMessageStr}</Typography>
                </Box>

                <Box className=" flex" >
                    <Typography fontSize={font.size.fs_6} color={theme.palette.common.white} className="opacity-80">{dayjs(data?.createdAt).format("DD MMM YYYY, hh:mm A")}</Typography>
                </Box>
            </Box>

            {/* <Avatar className="shadow-md" sx={{ width: "2rem", height: "2rem", background: theme.palette.primary.main }}>
                {data?.sentBy === AI_Response_SentBy.user ?
                    <AccountCircleOutlined sx={{ color: theme.palette.common.white }} />
                    : <AutoAwesomeOutlinedIcon sx={{ color: theme.palette.common.white }} />
                }
            </Avatar> */}
        </Box>
    )
}


// use to show generated module UI
function ResponseModuleComponent({ response }) {
    return (
        <Box className="w-full flex flex-col gap-0 px-4 py-2">
            <LightTooltip placement="left" title={<Box className="w-full flex flex-col gap-0 ">
                <Typography fontSize={font.size.fs_5}>Title: {response?.name}</Typography>
                <Typography fontSize={font.size.fs_5} className="">Description: {response?.description}</Typography>
            </Box>}>
                <Box className="w-full flex flex-col gap-0 ">
                    <Typography fontSize={font.size.fs_5}>Title: {response?.name}</Typography>
                    <Typography fontSize={font.size.fs_5} className="line-clamp-3">Description: {response?.description}</Typography>
                </Box>
            </LightTooltip>
        </Box>
    )
}