import { colors, createTheme, responsiveFontSizes } from "@mui/material";
import { common, grey, indigo, purple, red } from "@mui/material/colors";

/* Color variables */
export const COLORS = {
    col_1: "#F5F5F5",
    // col_2: "#A75646",
    col_2: "#5B478E",


    col_3: "#ACCF53",
    col_4: "#FFFF",
    col_5: "#A9D5F5",
    col_5_secondary: "#51C0D1",
    col_6: "#5D535C",
    col_7: "#F3F3F3",
    col_8: "#F5F7F9",
    col_9: '#5C478C',
    col_10: '#F2E207',
    col_11: '#ACCF53',
    col_12: '#3cd725',
    col_13: '#ce1515',
    col_14: '#F1ACBF',
    col_15: '#A8D5F2',
    col_16: "rgb(20 83 45)", // text-green-900
    col_17: "rgb(88 28 135)", // text-purple-900
    col_18: "rgb(120 53 15)", // text-amber-900
    col_19: "rgb(127 29 29)", // text-red-900
    col_20: "rgb(17 24 39)",  // text-gray-900
    col_21: "rgb(131 24 67)",  //  text-pink-900
    col_22: "rgb(113 63 18)",  //  text-yellow-900
};

/* MUI theme palette for overidiing and apply custom themes */
let theme = createTheme({
    typography: {
        fontSize: 14,
        // fontFamily: ["Robotto", "Poppins", "sans-serif"],
        fontFamily: [
            "Montserrat",
            "Poppins",
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            main: purple[600],
            col_white: grey[50],
            col_red: red[500],
            col_theme_1: grey[200],
            disabled: grey[300],
            layout_col: "#F0F1F5",
            layout_dark_col: grey[300],
            tableHeader: grey[200],
            h_card_1: grey[50],
            h_card_2: grey[50],
            h_card_3: grey[50],
            h_card_4: grey[50],
            h_card_5: grey[50],
        },
        secondary: {
            main: indigo[600],
            col_white: grey[50],
            col_red: red[500],
            col_theme_1: grey[200],
            disabled: grey[300],
            layout_col: grey[300],
            layout_dark_col: grey[300],
            tableHeader: grey[200],
            h_card_1: grey[50],
            h_card_2: grey[50],
            h_card_3: grey[50],
            h_card_4: grey[50],
            h_card_5: grey[50],
        },
        dark: {
            main: grey[600],
            col_white: grey[50],
            col_red: red[500],
            col_theme_1: grey[200],
            disabled: grey[300],
            layout_col: grey[300],
            layout_dark_col: grey[300],
            tableHeader: grey[200],
            h_card_1: grey[50],
            h_card_2: grey[50],
            h_card_3: grey[50],
            h_card_4: grey[50],
            h_card_5: grey[50],
        },
        typography: {
            colors: {
                primary: purple[500]
            },
            primary: {
                main: grey[500],
                col_white: grey[50],
            },
        },
    },
    transitions: {
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            // most basic recommended timing
            standard: 300,
            // this is to be used in complex animations
            complex: 375,
            // recommended when something is entering screen
            enteringScreen: 225,
            // recommended when something is leaving screen
            leavingScreen: 195,
        },
    },
    shape: {
        borderRadius: "0.6rem"
    },
    components: {
        MuiButton: {
            defaultProps: {
                sx: {
                    // color: "#FFF",
                    // borderRadius: "1rem",
                },
                size: "small",
                // disableRipple: true,
                // disableElevation: true,
            },
            styleOverrides: {
                root: {
                    whiteSpace: "nowrap",
                    textTransform: "capitalize",
                },
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: "small",
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    transition: "all 0.3s ease-in-out",
                }
            }
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                placement: "top"
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    borderRadius: "12px"
                }
            },
            defaultProps: {
                size: "small",
            }
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
                variant: "outlined",
            },
            styleOverrides: {
                "& label.Mui-focused": {
                    color: COLORS.col_2,
                },
                "& .MuiInput-underline:after": {
                    borderBottomColor: COLORS.col_2,
                },
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: COLORS.col_2,
                    },
                    "&:hover fieldset": {
                        borderColor: COLORS.col_2,
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: COLORS.col_2,
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "7px",
                    borderColor: COLORS.col_2,
                },
            },
        },
        MuiDialog: { styleOverrides: { root: { backdropFilter: 'blur(2px)', } } },
    },
});


export const themePalette = responsiveFontSizes(theme);