import { Add, Close, Create } from "@mui/icons-material"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TextField, Typography, useTheme } from "@mui/material"
import { grey } from "@mui/material/colors";
import { useState } from "react";


function CreateStoryDialog({ open, closeDialog, handleContinue, }) {
    const theme = useTheme();

    const [acceptanceCriteria, setAcceptanceCriteria] = useState([]);
    const [accetanceCriteriaText, setAccetanceCriteriaText] = useState("");

    const handleAddAcceptanceCriteria = () => {
        setAcceptanceCriteria([...acceptanceCriteria, accetanceCriteriaText]);
        setAccetanceCriteriaText("");
    }

    const handleRemoveAcceptanceCriteria = (index) => {
        setAcceptanceCriteria(acceptanceCriteria.filter((_, i) => i !== index));
    }

    return (
        <Box className="flex flex-col px-4 w-full h-full z-20 absolute left-0 top-0 "
            bgcolor={theme.palette.primary.layout_col}
        >
            <Box className="flex items-center  pb-4 gap-4">
                <Typography variant="h6" className="flex-1">Create Story</Typography>

                <Button variant="contained" color="inherit" size="small" startIcon={<Close />} onClick={closeDialog} >Close</Button>
                <Button variant="contained" size="small" startIcon={<Add />} onClick={closeDialog} >Create</Button>
            </Box>


            <Box className="flex flex-col  gap-4 pb-4">

                <Box className="flex flex-col gap-2">
                    <Typography variant="body1">Title:</Typography>
                    <TextField size="small" placeholder="Title" fullWidth sx={{ "& .MuiOutlinedInput-root": { borderRadius: "12px" }, }} />
                </Box>


                <Box className="flex flex-col gap-2">
                    <Typography variant="body1">Description:</Typography>
                    <Box className="flex flex-col gap-4">
                        <TextField size="small" placeholder="Description" fullWidth multiline minRows={6} maxRows={6} sx={{ "& .MuiOutlinedInput-root": { borderRadius: "12px" }, }} />
                    </Box>
                </Box>

                <Box className="flex flex-col gap-2">
                    <Typography variant="body1">Acceptance Criteria:</Typography>
                    <Box className="flex items-center gap-4">
                        <TextField size="small" placeholder="Enter acceptance criteria" fullWidth value={accetanceCriteriaText} onChange={(e) => setAccetanceCriteriaText(e.target.value)} sx={{ "& .MuiOutlinedInput-root": { borderRadius: "12px" }, }} />
                        <Button size="small" variant="outlined" disabled={!accetanceCriteriaText} startIcon={<Add />} onClick={handleAddAcceptanceCriteria} sx={{ borderRadius: "28px" }}>Add</Button>
                    </Box>

                    <Box className="flex flex-col gap-2">
                        {acceptanceCriteria?.map((criteria, index) => (
                            <Box key={index} className="flex items-center gap-2 border border-gray-400 rounded-full px-2 pl-4 py-2">
                                <Typography>{index + 1}.</Typography>
                                <Typography className="flex-1">{criteria}</Typography>
                                <IconButton size="small" onClick={() => handleRemoveAcceptanceCriteria(index)}><Close /></IconButton>
                            </Box>
                        ))}
                    </Box>
                </Box>

                {/* <Box className="flex justify-end gap-2">
                    <Button size="small" variant="outlined" startIcon={<Close />} onClick={closeDialog} sx={{ borderRadius: "28px" }}>Cancel</Button>
                    <Button size="small" variant="contained" startIcon={<Add />} onClick={handleContinue} sx={{ borderRadius: "28px" }}>Continue</Button>
                </Box> */}



            </Box>

        </Box>
    )
}

export default CreateStoryDialog
