import { AccountCircleOutlined, ArrowRightAlt, Delete, Edit, FolderOutlined, MoreHoriz, PlayCircleOutline } from "@mui/icons-material"
import { Box, Chip, IconButton, Menu, MenuItem, Popover, Typography, useTheme } from "@mui/material"
import { font } from "../../styles/font"
import { blue, brown, green, grey, red, yellow } from "@mui/material/colors";
import { useState } from "react";
import { LightTooltip } from "../styled/CustomComponent";

class EXECUTION_STATUS {
    static PENDING = "Pending";
    static IN_PROGRESS = "In progress";
    static COMPLETED = "Completed";
    static FAILED = "Failed";
    static INTERUPPTED = "Interrupted";
}



function ExecutionCard({ item, handleClick }) {
    const theme = useTheme();


    function GetExecutionStatusColor(execution_status) {
        // console.log(story_status, "story_status")
        switch (execution_status?.toLowerCase()) {
            case EXECUTION_STATUS.PENDING?.toLowerCase():
                return yellow[500];
            case EXECUTION_STATUS.IN_PROGRESS?.toLowerCase():
                return blue[500];
            case EXECUTION_STATUS.INTERUPPTED?.toLowerCase():
                return brown[500];
            case EXECUTION_STATUS.COMPLETED?.toLowerCase():
                return green[500];
            case EXECUTION_STATUS.FAILED?.toLowerCase():
                return red[500];
            default:
                return yellow[500];
        }
    }

    return (
        <Box
            className="bg-white flex flex-col w-full h-full box-content"
            sx={{
                borderBottom: `4px solid ${GetExecutionStatusColor(item?.status?.toLowerCase())}`,
                // borderStyle: "inset",
                // "@media (max-width: 600px)": {
                //     width: "100%",
                // },
            }}
        >

            {/* upper container */}
            <Box className="flex items-center gap-2 px-2 py-1 border-b">
                <Typography fontSize={font.size.fs_4} className=" line-clamp-3 flex-1">{item?.name}</Typography>
                <LightTooltip title="Execute">
                    <IconButton sx={{ fontSize: font.size.fs_6 }} color="primary" onClick={() => { }}>
                        <PlayCircleOutline />
                    </IconButton>
                </LightTooltip>
            </Box>


            {/* bottom container */}
            <Box className="flex items-end justify-between gap-2 px-2 py-2 flex-1 cursor-pointer " onClick={handleClick}>

                <Chip
                    variant="contained"
                    size="small"
                    label={item?.status}
                    className="flex-1 transition-all ease-in-out duration-300 cursor-pointer line-clamp-1"
                    sx={{ background: GetExecutionStatusColor(item?.status?.toLowerCase()), color: "white" }}
                />

                <LightTooltip title={`Executed On: 26 November 2024`}>
                    <Typography fontSize={font.size.fs_5} className="flex-1 line-clamp-1">Executed On: 26 November 2024</Typography>
                </LightTooltip>
            </Box>


        </Box>
    )
}

export default ExecutionCard

