import axios from "axios";
import { MAIN_URL, REQUEST_URL } from "../../constant/ApplicationVariables";

export const login_API = async (body) => {
    try {
        const response = await axios({
            url: MAIN_URL.url + REQUEST_URL.login,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}
