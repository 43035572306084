import { AccountCircleOutlined, Add, ArrowRightAlt, Delete, Description, Edit, FolderOutlined, MoreHoriz } from '@mui/icons-material';
import { Avatar, Box, Button, Chip, Divider, Fab, IconButton, Typography } from '@mui/material'
import StoryPreview from '../story/StoryPreview.js';
import { useApplicationContext } from '../../context/ApplicationContext';
import CreateStoryComponent from '../dialog/CreateStoryDialog.js';
import { useState } from 'react';
import { blue, brown, green, grey, purple, red } from '@mui/material/colors';
import { font } from '../../styles/font';
import MarketplaceCard from './MarketplaceCard.js';
import MarketplacePreview from './MarketplacePreview.js';

class STORY_STATUS {
    static DRAFT = "Draft";
    static APPROVED = "Approved";
    static UNDER_DEVELOPMENT = "Under development";
    static CLOSED = "Closed";
}

function MarketplaceContainer() {
    const { currentUserStory, setCurrentUserStory, openStoryPreview, setOpenStoryPreview } = useApplicationContext();

    const [openCreateStoryDialog, setOpenCreateStoryDialog] = useState(false);
    const [currentStoryFilter, setCurrentStoryFilter] = useState("all");


    let storyList = [
        {
            name: "Create a new user profile",
            description: "The user is on the sign up page. The user enters the required information such as name, email, password, and phone number. The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                "The user should not be able to create a new user profile if the required information is not provided.",
                "The user should not be able to create a new user profile if the email address already exists."
            ]
        },
        {
            name: "Log in to the service",
            description: "The user is on the login page. The user enters the email address and password. The user should be able to log in to the service with the email address and password.",
            type: "story",
            id: 2,
            status: "Approved",
            acceptanceCriteria: [
                "The user should be able to log in to the service with the email address and password.",
                "The user should not be able to log in to the service if the email address or password is incorrect.",
                "The user should not be able to log in to the service if the email address does not exist."
            ]
        },
        {
            name: "Reset password",
            description: "The user is on the reset password page. The user enters the email address. The user should be able to reset the password with the email address.",
            type: "story",
            id: 3,
            status: "Under development",
            acceptanceCriteria: [
                "The user should be able to reset the password with the email address.",
                "The user should not be able to reset the password if the email address does not exist."
            ]
        },
        {
            name: "Shopping Website",
            description: "The user is on the shopping website. The user places an order. The user should receive a confirmation message after placing an order.",
            type: "project",
            id: 4,
            status: "Completed",
            acceptanceCriteria: [
                "The platform should allow users to browse products.",
                "The platform should provide a secure checkout process.",
                "The platform should have an admin panel to manage products."
            ]
        },
        {
            name: "Banking App",
            description: "The user is on the banking app. The user logs in. The user should be able to log in to the banking app.",
            type: "project",
            id: 5,
            status: "Closed",
            acceptanceCriteria: [
                "The app should allow users to check their account balance.",
                "The app should enable users to transfer money securely.",
                "The app should provide transaction history and notifications."
            ]
        },
        {
            name: "Create a new project",
            description: "The user is on the project page. The user enters the name and description of the project. The user should be able to create a new project with the required information such as name and description.",
            type: "story",
            id: 6,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new project with the required information such as name and description.",
                "The user should not be able to create a new project if the required information is not provided.",
                "The user should not be able to create a new project if the project name already exists."
            ]
        },
        {
            name: "Edit a project",
            description: "The user is on the edit project page. The user enters the name and description of the project. The user should be able to edit the project with the required information such as name and description.",
            type: "story",
            id: 7,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to edit the project with the required information such as name and description.",
                "The user should not be able to edit the project if the required information is not provided.",
                "The user should not be able to edit the project if the project name already exists."
            ]
        }
    ];

    const handleOpenStory = (story) => {
        setCurrentUserStory(story);
        setOpenStoryPreview(true);
    }

    return (
        <Box className=" flex flex-col gap-4 relative h-full w-full box-border overflow-hidden">

            {/* header */}
            <Box className="w-full flex justify-between">
                <Typography variant="h6">Market Place</Typography>


                <Box className="flex items-center gap-2">
                    <Avatar sx={{ width: "25px", height: "25px", bgcolor: purple[500], fontSize: "14px" }}>
                        {storyList
                            ?.filter((item) => item?.type === "story")?.length
                            ?.toString()}
                    </Avatar>
                </Box>
            </Box>

            {/* story list */}
            <Box className=" grow flex  flex-col gap-4">

                {/* category */}
                <Box className=" flex items-center justify-center gap-4 overflow-x-auto">
                    {
                        ['Feature', 'Bug', 'Improvement', 'Task', 'Research'].map((category, index) => {
                            return (
                                <Button size="small" variant="outlined" sx={{ color: "black",borderRadius : "20px" }}>
                                    {category}
                                </Button>
                            )
                        })
                    }
                </Box>

                {/* list container */}
                <Box className=" grow flex  overflow-y-auto ">
                    {storyList
                        ?.filter((item) => item?.type === "story")?.length === 0 ?
                        <Box className="grow flex flex-col gap-2 justify-center items-center ">
                            <Description sx={{ fontSize: font.size.fs_1 }} />
                            <Typography fontSize={font.size.fs_5}>No story</Typography>
                        </Box>
                        :
                        (
                            storyList
                                ?.filter((item) => item?.type === "story")
                                ?.filter((item) => currentStoryFilter === "all" ? true : item?.status?.toLowerCase() === currentStoryFilter)?.length === 0 ?
                                (
                                    <Box className="grow flex flex-col gap-2 justify-center items-center ">
                                        <Description sx={{ fontSize: font.size.fs_1 }} />
                                        <Typography fontSize={font.size.fs_5}>No {currentStoryFilter} story</Typography>
                                    </Box>
                                ) :

                                (
                                    <Box className=" min-w-full h-fit flex flex-wrap gap-6 ">
                                        {
                                            storyList
                                                ?.filter((item) => item?.type === "story")
                                                ?.filter((item) => currentStoryFilter === "all" ? true : item?.status?.toLowerCase() === currentStoryFilter)
                                                ?.map((item, index) => {
                                                    return (
                                                        <Box
                                                            // className="bg-white rounded-lg shadow hover:shadow-lg transition-all ease-in-out duration-300 cursor-pointer h-fit min-w-[150px] w-[100%] sm:w-[48%] md:w-[31%] lg:w-[24%] xl:w-[18%] 2xl:w-[18.6%]"
                                                            className="bg-white overflow-hidden rounded-lg border  hover:shadow-xl transition-all ease-in-out duration-300 h-[115px] min-w-[120px] w-[98%] sm:w-[47%] md:w-[31%] lg:w-[23%]"
                                                            key={index}
                                                        >
                                                            <MarketplaceCard item={item} handleClick={() => handleOpenStory(item)} />
                                                        </Box>
                                                    )
                                                })
                                        }

                                    </Box>
                                )
                        )
                    }
                </Box>
            </Box>



            {openStoryPreview && currentUserStory && <MarketplacePreview />}
            {openCreateStoryDialog && <CreateStoryComponent open={openCreateStoryDialog} closeDialog={() => setOpenCreateStoryDialog(false)} handleContinue={() => setOpenCreateStoryDialog(false)} />}

        </Box>
    )
}

export default MarketplaceContainer
