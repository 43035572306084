import { AccountCircleOutlined, Add, ArrowRightAlt, Clear, Close, Delete, Description, Edit, FilterAltOutlined, FolderOutlined, MoreHoriz } from '@mui/icons-material';
import { Avatar, Box, Breadcrumbs, Button, Chip, Divider, Fab, FormControl, Icon, IconButton, InputLabel, MenuItem, Popover, Select, Typography, useTheme } from '@mui/material'
import { useApplicationContext } from '../../context/ApplicationContext';
import { useEffect, useState } from 'react';
import { grey, purple } from '@mui/material/colors';
import { font } from '../../styles/font';
import ProjectCard from './ProjectCard';
import CreateProjectDrawer from '../drawer/CreateProject';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LightTooltip } from '../styled/CustomComponent';
import EditProjectDrawer from '../drawer/EditProject';
import { deleteProject_API, getProjectCategoryList_API, getProjectList_API, getProjectSubcategoryList_API } from '../../api/controller/ProjectController';
import { Severity } from '../../constant/ApplicationVariables';
import { HttpStatusCode } from 'axios';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import DeleteProjectDialog from '../dialog/DeleteProjectDialog';
import { getFolderStructureList_API } from '../../api/controller/RequirementController';

export default function ProjectsContainer() {
    const { openStoryPreview, setOpenStoryPreview, openStoryConfirmationPreview, setOpenStoryConfirmationPreview, folderStructureList, setFolderStructureList,
        projectList, setProjectList, activeProject, setActiveProject, openCreateProjectDrawer, setOpenCreateProjectDrawer, openEditProjectDrawer, setOpenEditProjectDrawer, openDeleteProjectDialog, setOpenDeleteProjectDialog, globalLoader, setGlobalLoader, handleSnackbar, activeProjectSubcategory, setActiveProjectSubcategory, activeProjectCategory, setActiveProjectCategory, headerSearchText, setHeaderSearchText } = useApplicationContext();
    const theme = useTheme();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();

    // const [openCreateProjectDrawer, setOpenCreateProjectDrawer] = useState(false);
    // const [openEditProjectDrawer, setOpenEditProjectDrawer] = useState(false);
    const [currentStoryFilter, setCurrentStoryFilter] = useState("all");
    // const [activeProjectCategory, setActiveProjectCategory] = useState(null);


    let projectsList = [
        {
            name: "Trunk2tale",
            description: "A website to buy products",
            categoryName: "Entertainment",
            subCategoryName: "Social Media",
            status: "Active",
            createdAt: "03 - 12 - 2024 18:00",
        },
        {
            name: "Event Management",
            description: "A platform to share posts and connect with friends",
            categoryName: "Entertainment",
            subCategoryName: "Social Media",
            status: "Active",
            createdAt: "03 - 12 - 2024 18:00",
        },

        {
            name: "Test Management",
            description: "A system to manage money online",
            categoryName: "Management",
            subCategoryName: "  Social Media",
            status: "Active",
            createdAt: "03 - 12 - 2024 18:00",
        },

        {
            name: "Mindamigo",
            description: "A website to buy and sell products",
            categoryName: "Health",
            subCategoryName: "  Social Media",
            status: "Active",
            createdAt: "03 - 12 - 2024 18:00",
        },

        {
            name: "Education Platform",
            description: "A platform to learn new things",
            categoryName: "Education",
            subCategoryName: "  Social Media",
            status: "Active",
            createdAt: "03 - 12 - 2024 18:00",
        },

        {
            name: "Fitness App",
            description: "An app to track workouts and diet",
            categoryName: "Health",
            subCategoryName: "  Social Media",
            status: "Active",
            createdAt: "03 - 12 - 2024 18:00",
        }

        // {
        //     name: "Education Platform",
        //     description: "A platform to learn new things",
        //     category: "Education",
        //     id: 5,
        // },
        // {
        //     name: "Fitness App",
        //     description: "An app to track workouts and diet",
        //     category: "Health",
        //     id: 6,
        // },
        // {
        //     name: "Music Streaming Service",
        //     description: "A service to stream and listen to music",
        //     category: "Entertainment",
        //     id: 7,
        // },
        // {
        //     name: "Travel Booking System",
        //     description: "A system to book travel tickets",
        //     category: "Travel",
        //     id: 8,
        // },
        // {
        //     name: "Weather App",
        //     description: "An app to check the weather forecast",
        //     category: "Utility",
        //     id: 9,
        // },
        // {
        //     name: "Recipe Sharing Platform",
        //     description: "A platform to share and discover recipes",
        //     category: "Food",
        //     id: 10,
        // },
        // {
        //     name: "Real Estate Listing Website",
        //     description: "A website to list and find properties",
        //     category: "Real Estate",
        //     id: 11,
        // },
        // {
        //     name: "Project Management Tool",
        //     description: "A tool to manage and track projects",
        //     category: "Productivity",
        //     id: 12,
        // },
        // {
        //     name: "Online Learning Portal",
        //     description: "A portal to access educational courses",
        //     category: "Education",
        //     id: 13,
        // },
        // {
        //     name: "Digital Marketing Platform",
        //     description: "A platform for digital marketing solutions",
        //     category: "Marketing",
        //     id: 14,
        // },
        // {
        //     name: "Photo Editing Software",
        //     description: "Software to edit and enhance photos",
        //     category: "Photography",
        //     id: 15,
        // },
        // {
        //     name: "Event Management System",
        //     description: "A system to organize and manage events",
        //     category: "Event Management",
        //     id: 16,
        // },
        // {
        //     name: "Customer Support Chatbot",
        //     description: "A chatbot to assist with customer support",
        //     category: "Customer Service",
        //     id: 17,
        // },
        // {
        //     name: "News Aggregator",
        //     description: "A service to aggregate and display news articles",
        //     category: "News",
        //     id: 18,
        // },
        // {
        //     name: "Job Portal",
        //     description: "A portal to find and post job listings",
        //     category: "Recruitment",
        //     id: 19,
        // },
        // {
        //     name: "Online Auction Platform",
        //     description: "A platform to bid and auction items",
        //     category: "E-commerce",
        //     id: 20,
        // },
        // {
        //     name: "Virtual Reality Game",
        //     description: "A game designed for virtual reality",
        //     category: "Gaming",
        //     id: 21,
        // },
        // {
        //     name: "Language Learning App",
        //     description: "An app to learn new languages",
        //     category: "Education",
        //     id: 22,
        // },
        // {
        //     name: "Task Scheduler",
        //     description: "A tool to schedule and manage tasks",
        //     category: "Productivity",
        //     id: 23,
        // },
        // {
        //     name: "Investment Tracking App",
        //     description: "An app to track and manage investments",
        //     category: "Finance",
        //     id: 24,
        // },
        // {
        //     name: "Online Grocery Store",
        //     description: "A store to buy groceries online",
        //     category: "E-commerce",
        //     id: 25,
        // },
    ];

    let categoryList = [
        {
            name: "E-commerce",
            id: 1,
            subCateory: [
                {
                    name: "Online Store",
                    id: 11
                },
                {
                    name: "Marketplace",
                    id: 12
                },
                {
                    name: "Online Booking",
                    id: 13
                },
                {
                    name: "Online Shopping",
                    id: 14
                },
            ]
        },
        {
            name: "Social Media",
            id: 2,
            subCateory: [
                {
                    name: "Online Forum",
                    id: 21
                },
                {
                    name: "Online Community",
                    id: 22
                },
                {
                    name: "Blogging",
                    id: 23
                },
            ]
        },
        {
            name: "Banking",
            id: 3,
            subCateory: [
                {
                    name: "Online Banking",
                    id: 31
                },
                {
                    name: "Personal Finance",
                    id: 32
                },
                {
                    name: "Investment Services",
                    id: 33
                },
            ]
        },
        {
            name: "Education",
            id: 4,
            subCateory: [
                {
                    name: "Online Learning",
                    id: 41
                },
                {
                    name: "Online Courses",
                    id: 42
                },
                {
                    name: "Online Degree",
                    id: 43
                },
            ]
        },
        {
            name: "Health",
            id: 5,
            subCateory: [
                {
                    name: "Medical Records",
                    id: 51
                },
                {
                    name: "Medical Billing",
                    id: 52
                },
                {
                    name: "Telemedicine",
                    id: 53
                },
            ]
        },
        {
            name: "Entertainment",
            id: 6,
            subCateory: [
                {
                    name: "Movies",
                    id: 61
                },
                {
                    name: "Music",
                    id: 62
                },
                {
                    name: "Gaming",
                    id: 63
                },
            ]
        },
        {
            name: "Travel",
            id: 7,
            subCateory: [
                {
                    name: "Flights",
                    id: 71
                },
                {
                    name: "Hotels",
                    id: 72
                },
                {
                    name: "Car Rentals",
                    id: 73
                },
            ]
        },
        {
            name: "Utility",
            id: 8,
            subCateory: [
                {
                    name: "Electricity",
                    id: 81
                },
                {
                    name: "Gas",
                    id: 82
                },
                {
                    name: "Water",
                    id: 83
                },
            ]
        },
    ];


    const handleOpenProject = (projectData) => {
        setActiveProject(projectData);

        handleGetFolderStructureList(projectData);


        // const folders = [
        //     {
        //         id: "1",
        //         name: projectData?.name,
        //         type: "project",
        //         moduleCount: 2,
        //         children: [
        //             {
        //                 id: "m1",
        //                 name: "Settings",
        //                 type: "module",
        //                 userStoryCount: 5,

        //             },
        //             {
        //                 id: "m2",
        //                 name: "Profile",
        //                 type: "module",
        //                 userStoryCount: 5,

        //             },
        //         ],
        //     },
        // ];
        // console.log(folders, "folders");
        // setFolderStructureList(folders);



        // navigate(`/requirements`);
    }

    const handleOpenProjectEdit = (projectData) => {
        setActiveProject(projectData);
        setOpenEditProjectDrawer(true);
    }

    const handleOpenCreateDialog = () => {
        setOpenCreateProjectDrawer(true);
    }

    const handleDeleteProject = (projectData) => {
        setActiveProject(projectData);
        setOpenDeleteProjectDialog(true);
    }

    const handleFilterProject = (item, index) => {
        if (activeProjectCategory && activeProjectSubcategory) {
            return item?.categoryName?.toLowerCase() === activeProjectCategory?.name?.toLowerCase() && item?.subCategoryName?.toLowerCase() === activeProjectSubcategory?.name?.toLowerCase();
        } else if (activeProjectCategory && !activeProjectSubcategory) {
            return item?.categoryName?.toLowerCase() === activeProjectCategory?.name?.toLowerCase();
        } else if (activeProjectSubcategory && !activeProjectCategory) {
            return item?.subCategoryName?.toLowerCase() === activeProjectSubcategory?.name?.toLowerCase();
        } else {
            return true
        }



        // (activeProjectCategory && activeProjectSubcategory) ?
        //                                             (item.categoryName?.toLowerCase() === activeProjectCategory?.name?.toLowerCase()
        //                                                 && item?.subCategoryName?.toLowerCase() === activeProjectSubcategory?.name?.toLowerCase())
        //                                             : (activeProjectCategory && !activeProjectSubcategory) ?
        //                                                 (item.categoryName?.toLowerCase() === activeProjectCategory?.name?.toLowerCase())
        //                                                 : (activeProjectSubcategory && !activeProjectCategory) ?
        //                                                     (item?.subCategoryName?.toLowerCase() === activeProjectSubcategory?.name?.toLowerCase())
        //                                                     : true
    }

    const handleProjectSearchFilter = (item, index) => {
        if (headerSearchText) {
            return item.name?.toLowerCase().includes(headerSearchText?.toLowerCase())
        } else {
            return item
        }
    }

    // const handleCategoryClick = (category) => {
    //     setActiveProjectCategory(category);
    // }


    const handleGetProjectList = async () => {
        try {
            setGlobalLoader(true);

            const response = await getProjectList_API();

            if (response.status === HttpStatusCode.Ok) {
                setProjectList(response.data);
                // handleSnackbar(response.message, Severity.success);
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, "projectreponse errorr")
        }
    }

    const handleGetFolderStructureList = async (project) => {
        try {
            setGlobalLoader(true);

            let query = `name=${project?.name}`

            const response = await getFolderStructureList_API(query);

            if (response.status === HttpStatusCode.Ok) {
                setFolderStructureList(response.data);
                // setFolderStructureList([{ ...response.data }]);
                navigate(`/requirements`);
                handleSnackbar(response?.message, Severity.success);
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, "projectreponse errorr")
        }
    }

    const handleDelete = async () => {
        try {
            setGlobalLoader(true);

            let query = `name=${activeProject?.name}`

            const response = await deleteProject_API(query);

            if (response.status === HttpStatusCode.Ok) {
                handleGetProjectList();
                handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, "projectreponse errorr")
        }
    }

    useEffect(() => {
        handleGetProjectList();
    }, [])

    return (
        <Box className=" flex flex-col gap-0 relative h-full w-full box-border overflow-y-auto" >

            {!openStoryPreview && !openStoryConfirmationPreview &&
                <Box className="fixed bottom-4 right-4 z-10">
                    <LightTooltip title={`Create Project`}>
                        <Fab
                            color="primary"
                            onClick={handleOpenCreateDialog}
                            style={{ position: "absolute", bottom: "10px", right: "10px" }}>
                            <Add />
                        </Fab>
                    </LightTooltip>
                </Box>}

            {/* header */}
            <Box className="w-full flex justify-between sticky top-0 z-10 backdrop-blur-sm">
                <Header projectsList={projectList?.filter(handleFilterProject)?.filter(handleProjectSearchFilter)} />
            </Box>

            {/* items list */}
            <Box className=" grow flex" >
                {projectList?.length === 0 ?
                    <Box className="grow flex flex-col gap-2 justify-center items-center ">
                        <Description sx={{ fontSize: font.size.fs_1 }} />
                        <Typography fontSize={font.size.fs_5}>No projects</Typography>
                    </Box>
                    :
                    (
                        projectList
                            ?.filter(handleFilterProject)
                            ?.filter(handleProjectSearchFilter)?.length === 0 ?
                            (
                                <Box className="grow flex flex-col gap-2 justify-center items-center ">
                                    <Description sx={{ fontSize: font.size.fs_1 }} />
                                    <Typography fontSize={font.size.fs_5}>No projects </Typography>
                                </Box>
                            ) :

                            (
                                <Box className="w-full h-fit flex justify-center flex-wrap gap-8 px-4 py-6 ">
                                    {
                                        projectList
                                            ?.filter(handleFilterProject)
                                            ?.filter(handleProjectSearchFilter)
                                            ?.map((project, index) => {
                                                return (
                                                    <Box
                                                        key={index}
                                                        className=" overflow-hidden rounded-xl  hover:shadow-xl transition-all ease-in-out duration-300 h-44 min-w-[80px] w-[98%] sm:w-[47%] md:w-[31%] lg:w-[18%]"
                                                        // sx={{ border: `2px solid transparent`, "&:hover": { border: `2px solid ${theme.palette.primary.main}` } }}
                                                        bgcolor={theme.palette.common.white}
                                                    >
                                                        <ProjectCard item={project} handleClick={() => handleOpenProject(project)} handleEditClick={() => handleOpenProjectEdit(project)} handleClickDelete={() => handleDeleteProject(project)} />
                                                    </Box>
                                                )
                                            })
                                    }

                                </Box>
                            )
                    )
                }
            </Box>

            {openCreateProjectDrawer && <CreateProjectDrawer open={openCreateProjectDrawer} handleClose={() => setOpenCreateProjectDrawer(false)} />}
            {openEditProjectDrawer && <EditProjectDrawer open={openEditProjectDrawer} handleClose={() => setOpenEditProjectDrawer(false)} />}
            {openDeleteProjectDialog && <DeleteProjectDialog open={openDeleteProjectDialog} data={activeProject} handleConfirm={() => handleDelete()} handleClose={() => setOpenDeleteProjectDialog(false)} />}

        </Box>
    )
}



function Header({ projectsList }) {
    const { handleSnackbar, categoryList, setCategoryList, subCategoryList, setSubCategoryList, activeProjectCategory, setActiveProjectCategory, activeProjectSubcategory, setActiveProjectSubcategory } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();

    // const [categoryList, setCategoryList] = useState([]);
    // const [subCategoryList, setSubCategoryList] = useState([]);
    // const [activeProjectCategory, setActiveProjectCategory] = useState(null);
    // const [activeProjectSubcategory, setActiveProjectSubcategory] = useState(null);
    const [filterAnchorEl, setFilterAnchorEl] = useState(false);

    let categoryList1 = [
        {
            name: "E-commerce",
            id: 1,
            subCateory: [
                {
                    name: "Online Store",
                    id: 11
                },
                {
                    name: "Marketplace",
                    id: 12
                },
                {
                    name: "Online Booking",
                    id: 13
                },
                {
                    name: "Online Shopping",
                    id: 14
                },
            ]
        },
        {
            name: "Social Media",
            id: 2,
            subCateory: [
                {
                    name: "Online Forum",
                    id: 21
                },
                {
                    name: "Online Community",
                    id: 22
                },
                {
                    name: "Blogging",
                    id: 23
                },
            ]
        },
        {
            name: "Banking",
            id: 3,
            subCateory: [
                {
                    name: "Online Banking",
                    id: 31
                },
                {
                    name: "Personal Finance",
                    id: 32
                },
                {
                    name: "Investment Services",
                    id: 33
                },
            ]
        },
        {
            name: "Education",
            id: 4,
            subCateory: [
                {
                    name: "Online Learning",
                    id: 41
                },
                {
                    name: "Online Courses",
                    id: 42
                },
                {
                    name: "Online Degree",
                    id: 43
                },
            ]
        },
        {
            name: "Health",
            id: 5,
            subCateory: [
                {
                    name: "Medical Records",
                    id: 51
                },
                {
                    name: "Medical Billing",
                    id: 52
                },
                {
                    name: "Telemedicine",
                    id: 53
                },
            ]
        },
        {
            name: "Entertainment",
            id: 6,
            subCateory: [
                {
                    name: "Movies",
                    id: 61
                },
                {
                    name: "Music",
                    id: 62
                },
                {
                    name: "Gaming",
                    id: 63
                },
            ]
        },
        {
            name: "Travel",
            id: 7,
            subCateory: [
                {
                    name: "Flights",
                    id: 71
                },
                {
                    name: "Hotels",
                    id: 72
                },
                {
                    name: "Car Rentals",
                    id: 73
                },
            ]
        },
        {
            name: "Utility",
            id: 8,
            subCateory: [
                {
                    name: "Electricity",
                    id: 81
                },
                {
                    name: "Gas",
                    id: 82
                },
                {
                    name: "Water",
                    id: 83
                },
            ]
        },
    ];

    // let categoryList = [
    //     { "name": "Technology" },
    //     { "name": "Finance" },
    //     { "name": "Healthcare" }
    // ];

    // let subCategoryList = [
    //     { "name": "Software" },
    //     { "name": "Hardware" },
    //     { "name": "Banking" },
    //     { "name": "Investment" }
    // ]

    const handleChangeProjectCategory = (event) => {
        setActiveProjectCategory(event.target.value);
    }

    const handleChangeProjectSubcategory = (event) => {
        setActiveProjectSubcategory(event.target.value);
    }

    const handleClearFilter = () => {
        setActiveProjectCategory(null);
        setActiveProjectSubcategory(null);
    }

    const handleGetCategoryList = async () => {
        try {
            // setGlobalLoader(true);

            const response = await getProjectCategoryList_API();

            if (response.status === HttpStatusCode.Ok) {
                setCategoryList(response.data);
                // setActiveProjectCategory(response.data[0]);


                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response);
            }

            // setTimeout(() => {
            //     setGlobalLoader(false);
            // }, 1000);

            console.log(response, "categoryreponse data")
        } catch (error) {
            console.log(error, "categoryreponse errorr")
        }
    }

    const handleGetSubCategoryList = async () => {
        try {
            // setGlobalLoader(true);

            let query = `parentName=${activeProjectCategory?.name}`;

            const response = await getProjectSubcategoryList_API(query);

            if (response.status === HttpStatusCode.Ok) {
                setSubCategoryList(response.data);
                // setActiveProjectSubcategory(response.data[0]);
                setActiveProjectSubcategory(null);

                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                // errorHandler(response);
            }

            // setTimeout(() => {
            //     setGlobalLoader(false);
            // }, 1000);

            console.log(response, "subcategoryreponse data")
        } catch (error) {
            console.log(error, "subcategoryreponse errorr")
        }
    }

    useEffect(() => {
        handleGetCategoryList();
    }, []);


    useEffect(() => {
        if (activeProjectCategory) {
            handleGetSubCategoryList();
        }
    }, [activeProjectCategory]);



    console.log(activeProjectCategory, "activeProjectCategory")

    return (
        <Box className="w-full h-full flex items-center justify-between gap-4 px-4 py-2">

            <Box className="flex items-center gap-2">
                <Typography variant="h6">Projects</Typography>
                <Avatar sx={{ width: "25px", height: "25px", bgcolor: theme.palette.secondary.main, fontSize: "14px" }}>
                    {projectsList?.length?.toString()}
                </Avatar>
            </Box>

            {(activeProjectCategory || activeProjectSubcategory) && <Box className="flex flex-1 items-center  gap-2">
                <Breadcrumbs aria-label="breadcrumb" >
                    <Typography fontSize={font.size.fs_4}>{activeProjectCategory?.name}</Typography>
                    <Typography fontSize={font.size.fs_4}>{activeProjectSubcategory?.name}</Typography>
                </Breadcrumbs>
            </Box>}

            <Box className="flex items-center  gap-2">
                <Button variant={(activeProjectCategory || activeProjectSubcategory) ? "contained" : "outlined"} color="secondary" startIcon={<FilterAltOutlined />} onClick={(e) => setFilterAnchorEl(e.currentTarget)}>Filter</Button>
                {(activeProjectCategory || activeProjectSubcategory) && <IconButton onClick={handleClearFilter}>
                    <Close />
                </IconButton>}
            </Box>

            {/* <Box className="flex items-center gap-2">

                <Box className="flex items-center gap-2 rounded-xl px-2 py-1 bg-white">
                    <Typography>Category</Typography>
                    <Select size="small"
                        className="w-44 h-8"
                        variant="outlined"
                        value={activeProjectCategory?.name}
                        displayEmpty
                        renderValue={() => activeProjectCategory?.name}
                        onChange={handleChangeProjectCategory}
                    >
                        {
                            categoryList?.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index}>
                                        {item?.name}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </Box>

                <Box className="flex items-center gap-2 rounded-xl px-2 py-1 bg-white">
                    <Typography>SubCategory</Typography>
                    <Select size="small"
                        className="w-44 h-8"
                        variant="outlined"
                        value={activeProjectSubcategory?.name}
                        displayEmpty
                        renderValue={() => activeProjectSubcategory?.name}
                        onChange={handleChangeProjectSubcategory}
                    >
                        {
                            subCategoryList?.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index}>
                                        {item?.name}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </Box>
            </Box> */}


            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={Boolean(filterAnchorEl)}
                anchorEl={filterAnchorEl}
                onClose={() => { setFilterAnchorEl(null) }}
            >
                <Box className="flex flex-col  gap-2 px-4 py-2">

                    <Box className="flex items-center justify-between gap-2 rounded-xl px-2 py-1 bg-white">
                        <Typography>Category</Typography>
                        <Select size="small"
                            className="w-72 h-8"
                            variant="outlined"
                            value={activeProjectCategory?.name}
                            displayEmpty
                            renderValue={() => activeProjectCategory?.name}
                            onChange={handleChangeProjectCategory}
                        >
                            {
                                categoryList?.map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={index}>
                                            {item?.name}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                        {activeProjectCategory && <IconButton onClick={() => setActiveProjectCategory(null)}>
                            <Clear />
                        </IconButton>}
                    </Box>

                    <Box className="flex items-center justify-between gap-2 rounded-xl px-2 py-1 bg-white">
                        <Typography>SubCategory</Typography>
                        <Select size="small"
                            className="w-72 h-8"
                            variant="outlined"
                            value={activeProjectSubcategory?.name}
                            displayEmpty
                            renderValue={() => activeProjectSubcategory?.name}
                            onChange={handleChangeProjectSubcategory}
                        >
                            {
                                subCategoryList?.map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={index}>
                                            {item?.name}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>

                        {activeProjectSubcategory && <IconButton onClick={() => setActiveProjectSubcategory(null)}>
                            <Clear />
                        </IconButton>}
                    </Box>
                </Box>
            </Popover>
        </Box>
    )
}
