import { Alert, Box, Button, CircularProgress, Fab, Snackbar, useTheme } from '@mui/material'
import ApplicationHeader from './ApplicationHeader'
import RoutesComponent from '../../routes';
import React, { useEffect, useState } from 'react';
import { login_API } from '../../api/controller/userController';
import { useApplicationContext } from '../../context/ApplicationContext';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import { HttpStatusCode } from 'axios';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import ProtectedRoute from '../../routes/ProtectedRoute';
import ProjectsContainer from '../projects/ProjectsContainer';
import ExecutionContainer from '../execution';
import MarketplaceContainer from '../marketplace';
import StoryContainer from '../story';
import RequirementsContainer from '../projects/RequirementsContainer';
import LoginContainer from '../auth/login';
import { getFolderStructureList_API } from '../../api/controller/RequirementController';
import { FolderType, Severity } from '../../constant/ApplicationVariables';



export default function ApplicationLayout() {
    const { globalSnackbar, setGLobalSnackBar, globalLoader, setGlobalLoader, handleSnackbar, profileDetails, setProfileDetails } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation()?.pathname?.split("/")[1];

    const [isAuthenticated, setIsAuthenticated] = useState(window && window.localStorage.getItem("profileDetails") ? true : false);

    const login = () => {
        setIsAuthenticated(true);
    };

    const logout = () => {
        setIsAuthenticated(false);
    };

    const handleLogin = async () => {
        try {
            let body = {
                email: "testing@floxpad.com",
                password: "p@ssword"
            }

            const response = await login_API(body);

            if (response.status === HttpStatusCode.Ok) {
                localStorage.setItem("access-token", response.data.authToken);
                localStorage.setItem("profileDetails", JSON.stringify(response.data));
                setProfileDetails(response.data);
            }

            handleSnackbar(response.message, "success")
            if (response) {
                errorHandler(response);
            }

            console.log(response, "loginresponsne data")
        } catch (error) {
            console.log(error, "login errorr")
        }
    }

    useEffect(() => {
        if (window && !window.localStorage.getItem("profileDetails")) {
            setIsAuthenticated(false);
        }

        if (window && window.localStorage.getItem("profileDetails")) {
            setIsAuthenticated(true)
        }
    }, [pathName]);

    console.log(profileDetails, "profileDetails");

    return (

        <Box className="w-full h-screen flex overflow-hidden relative" bgcolor={theme.palette.primary.layout_col}>

            {/* <Button variant="contained" onClick={() => handleLogin()} sx={{ position: "absolute", top: "10px", left: "10px", zIndex: 9999 }}>
                login
            </Button> */}

            <Box className="w-full h-[100dvh] flex flex-col">
                <Routes>
                    <Route element={<ProtectedRoute isAuthenticated={profileDetails} />}>
                        <Route element={<ApplicationLayoutMainContainer />}>
                            <Route exact path='/home' element={<div>Home</div>} key='home' />
                            <Route path='/projects' element={<ProjectsContainer />} key='project' />
                            <Route path='/requirements' element={<RequirementsContainer />} key='requirements' />
                            <Route path='/story' element={<StoryContainer />} key='story' />
                            <Route path='/marketplace' element={<MarketplaceContainer />} key='marketplace' />
                            <Route path='/execution' element={<ExecutionContainer />} key='execution' />
                        </Route>
                    </Route>
                    {<Route path="/" element={<LoginContainer />} key="login" />}
                </Routes>
            </Box>

            {false && <Box className="w-full h-[100dvh] flex flex-col">

                {/* header */}
                <Box className="h-[11dvh] ">
                    <ApplicationHeader />
                </Box>

                {/* main container */}
                <Box className="h-[89dvh] flex grow px-0 pb-0 overflow-hidden " >
                    <ApplicationLayoutMainContainer />
                </Box>
            </Box>}







            <SnackbarT2T
                globalSnackbar={globalSnackbar}
                setGLobalSnackBar={setGLobalSnackBar}
            />

            {globalLoader && <GlobalLoader />}

        </Box>
    )
}

function ApplicationLayoutMainContainer() {
    const { isUpdateFolderStructure, setIsUpdateFolderStructure, setFolderStructureList, currentFolderData, activeProject, handleSnackbar } = useApplicationContext();
    const errorHandler = useErrorHandler();

    const handleGetFolderStructureList = async () => {
        try {
            let projectName = activeProject?.name;
            let query = `name=${projectName}`

            const response = await getFolderStructureList_API(query);
            // setIsUpdateFolderStructure(false);

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setFolderStructureList(response.data);
            }

        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setIsUpdateFolderStructure(false);
        }
    }

    useEffect(() => {
        if (isUpdateFolderStructure) {
            handleGetFolderStructureList();
        }
    }, [isUpdateFolderStructure])

    return (
        <Box className="h-full w-full flex flex-col" >
            {/* <RoutesComponent /> */}

            {/* header */}
            <Box className="h-[11dvh] ">
                <ApplicationHeader />
            </Box>

            {/* main container */}
            <Box className="h-[89dvh] flex grow px-0 pb-0 overflow-hidden " >
                <Outlet />
            </Box>
        </Box>
    )
}


const SnackbarT2T = ({ globalSnackbar, setGLobalSnackBar }) => (
    <Snackbar
        autoHideDuration={3000}
        sx={{ zIndex: '99999' }}
        onClose={() =>
            setGLobalSnackBar({
                open: false,
                title: "",
                severity: "",
            })
        }
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={globalSnackbar?.open}
    >
        <Alert
            severity={globalSnackbar?.severity}
            variant="filled"
            sx={{ width: "100%" }}
        >
            {globalSnackbar?.title}
        </Alert>
    </Snackbar>
);

const GlobalLoader = () => {
    return (
        <Box className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center w-full h-full z-20 backdrop-blur-sm">
            <CircularProgress />
        </Box>
    )
} 