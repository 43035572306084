import { Close } from '@mui/icons-material'
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import React from 'react'
import { font } from '../../styles/font'

export default function DeleteProjectDialog({ open, handleClose, handleConfirm, data }) {
    return (
        <Dialog open={open} onClose={handleClose}
            sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "400px" } }}
        >
            <Box className="flex flex-col gap-4 px-4 py-2">
                <Box className="flex items-center justify-between gap-4">
                    <Typography fontSize={font.size.fs_4}>Are you sure</Typography>

                </Box>

                <Box>
                    <Typography fontSize={font.size.fs_5}>You want to delete project {data?.name}</Typography>
                </Box>

                <Box className="flex items-center justify-end gap-4">
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={handleConfirm}>Confirm</Button>
                </Box>

            </Box>
        </Dialog>
    )
}