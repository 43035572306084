import { ArrowDropDown, Close, Delete, Description, Edit, Folder, FolderOutlined, More, MoreHoriz, MoreVert, NavigateNext, Pages, PlayArrow } from '@mui/icons-material';
import { Avatar, Box, Breadcrumbs, Button, CircularProgress, debounce, IconButton, MenuItem, Select, Skeleton, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material'
import { font } from '../../styles/font';
import { useEffect, useState } from 'react';
import { purple } from '@mui/material/colors';
import StoryCard from '../story/StoryCard';
import { useApplicationContext } from '../../context/ApplicationContext';
import { LightTooltip } from '../styled/CustomComponent';
import UserStoryTable from '../common/UserStoryTable';
import CreateStoryComponent from '../story/CreateStoryComponent';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import { HttpStatusCode } from 'axios';
import { Severity } from '../../constant/ApplicationVariables';
import { addStory_API, deleteStory_API, getStoryList_API, updateStory_API } from '../../api/controller/StoryController';
import DeleteStoryDialog from '../dialog/DeleteStoryDialog';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecialOutlined';
import EditStoryComponent from '../story/EditStoryComponent';


export default function RequirementMainContainer() {
    const { openCreateStoryDialog, setOpenCreateStoryDialog, openEditStoryDialog, setOpenEditStoryDialog, openDeleteStoryDialog, setOpenDeleteStoryDialog, currentFolderData, setCurrentFolderData, currentUserStoryData, userStoryList, setUserStoryList, globalLoader, setGlobalLoader, handleSnackbar, selectedUserStoryList, setSelectedUserStoryList } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();

    const [breadCrumbsList, setBreadCrumbsList] = useState([]);
    const [currentTab, setCurrentTab] = useState(1);


    function handleBreadCrumb() {
        let array = [];
        const projectActive = currentFolderData && currentFolderData?.level === 0;
        const moduleActive = currentFolderData && currentFolderData?.level === 1;


        let dataObject = currentFolderData;
        array.push(dataObject);

        while (dataObject?.level > 0) {
            dataObject = dataObject?.parent;
            array.push(dataObject);
        }


        // if (dataObject) {
        //     array.push(
        //         <LightTooltip title={<Typography fontSize={font.size.fs_5} className="capitalize">{`${dataObject?.data?.type}: ${dataObject?.data?.name}`}</Typography>}>
        //             <Typography
        //                 fontSize={font.size.fs_4}
        //                 className="cursor-pointer hover:underline"
        //                 // fontWeight={projectActive && "bold"}
        //                 key="1"
        //                 // sx={{ opacity: projectActive ? 1 : 0.8 }}
        //                 onClick={() => { }}>
        //                 {dataObject?.data?.name}
        //             </Typography>
        //         </LightTooltip>
        //     )
        // }

        // while (dataObject?.level > 0) {
        //     dataObject = dataObject?.parent;
        //     array.push(
        //         <LightTooltip title={<Typography fontSize={font.size.fs_5} className="capitalize">{`${dataObject?.data?.type}: ${dataObject?.data?.name}`}</Typography>}>
        //             <Typography
        //                 fontSize={font.size.fs_4}
        //                 className="cursor-pointer hover:underline"
        //                 // fontWeight={moduleActive && "bold"}
        //                 key="2"
        //                 // sx={{ opacity: moduleActive ? 1 : 0.8 }}
        //                 onClick={() => { }}>
        //                 {dataObject?.data?.name}
        //             </Typography>
        //         </LightTooltip>
        //     )

        // }

        setBreadCrumbsList(array?.reverse())
    }

    function handleChangeTab(event, newValue) {
        setCurrentTab(newValue);
    }

    function handleComponent(currentTab) {
        switch (currentTab) {
            case 0:
                return <DashboardContainerComponent />;
            case 1:
                return <TableContainerComponent />;
            default:
                return <TableContainerComponent />;
        }
    }

    const handleDelete = () => {
        for (let i = 0; i < selectedUserStoryList?.length; i++) {
            handleDeleteStory(selectedUserStoryList[i]);
        }

        handleSnackbar("Delete Successfully", Severity.success);
        setOpenDeleteStoryDialog(false);
        handleGetUserStoryList();
    }

    const handleGetUserStoryList = async () => {
        try {
            // setGlobalLoader(true);

            let body = {
                "moduleName": currentFolderData?.data?.name,
                "projectName": currentFolderData?.parent?.data?.name
            }

            const response = await getStoryList_API(body);

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
                // handleSnackbar(response?.message, Severity.success);
            }

            if (response.status !== HttpStatusCode.Ok) {
                errorHandler(response);
            }

            setTimeout(() => {
                // setGlobalLoader(false);
            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, "projectreponse errorr")
        }
    }

    const handleCreateStory = async (storyData) => {
        try {
            setGlobalLoader(true);

            let body = {
                ...storyData,
                "priority": "High",
                "moduleName": currentFolderData?.data?.name,
                "projectName": currentFolderData?.parent?.data?.name,
            }


            const response = await addStory_API(body);
            console.log(response, "response handleCreateStory")

            if (response) {
                errorHandler(response, setGlobalLoader);
            }

            if (response.status === HttpStatusCode.Ok) {
                handleGetUserStoryList();
                setOpenCreateStoryDialog(false);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);
        }
    }

    const handleUpdateStory = async (storyData) => {
        try {
            setGlobalLoader(true);

            let body = {
                ...storyData,
            }


            const response = await updateStory_API(body);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setGlobalLoader);
            }

            if (response?.status === HttpStatusCode.Ok) {
                handleGetUserStoryList();
                setOpenEditStoryDialog(false);
            }


            console.log(response, "projectreponse data")
        }  catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);
        }
    }

    const handleDeleteStory = async (storyData) => {
        try {
            setGlobalLoader(true);

            let body = {
                "customId": storyData?.customId,
                "moduleName": currentFolderData?.data?.name,
                "projectName": currentFolderData?.parent?.data?.name
            }

            const response = await deleteStory_API(body);

            if (response.status === HttpStatusCode.Ok) {
                // handleSnackbar(response?.message, Severity.success);
                // setOpenDeleteStoryDialog(false);
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.error(error, "projectreponse errorr")
        }
    }


    useEffect(() => {
        if (currentFolderData?.data?.type === "module") {
            handleGetUserStoryList();
        }

        if (currentFolderData?.data?.type === "project") {
            setUserStoryList([])
        }
    }, [currentFolderData]);


    console.log(breadCrumbsList, "node1breadcrumbs")
    console.log(selectedUserStoryList, "node1selectedUserStoryList")

    useEffect(() => {
        handleBreadCrumb();
    }, [currentFolderData]);

    return (
        <Box className="flex flex-col w-full h-full">
            <Box className="flex items-center justify-between gap-4 px-2 py-2 " bgcolor={theme.palette.primary.layout_col}>

                <Tabs value={currentTab} onChange={(event, newValue) => handleChangeTab(event, newValue)}>
                    <Tab value={0} label="Dashboard" />
                    <Tab value={1} label="User Story" />
                </Tabs>

                <Box className="flex px-2 py-0 rounded-lg border-2" >
                    <Breadcrumbs
                        separator={<NavigateNext fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {
                            breadCrumbsList?.map((item, index) => {
                                return (
                                    <LightTooltip title={<Typography fontSize={font.size.fs_5} className="capitalize">{`${item?.data?.type}: ${item?.data?.name}`}</Typography>}>
                                        <Box className="flex items-center gap-1" onClick={() => { setCurrentFolderData(item) }}>
                                            {item?.data?.type === "project" && <FolderSpecialIcon color={(breadCrumbsList?.length - 1 === index) ? "secondary" : "dark"} sx={{ fontSize: font.size.fs_3 }} />}
                                            {item?.data?.type === "module" && <FolderOutlined color={(breadCrumbsList?.length - 1 === index) ? "secondary" : "dark"} sx={{ fontSize: font.size.fs_3 }} />}
                                            <Typography
                                                fontSize={font.size.fs_5}
                                                className="cursor-pointer hover:underline"
                                                // fontWeight={moduleActive && "bold"}
                                                key={index + 1}
                                                color={(breadCrumbsList?.length - 1 === index) && "secondary"}
                                                sx={{ opacity: breadCrumbsList?.length - 1 === index ? 1 : 0.8 }}
                                                onClick={() => { }}>
                                                {item?.data?.name}
                                            </Typography>
                                        </Box>
                                    </LightTooltip>
                                )
                            })
                        }
                    </Breadcrumbs>
                </Box>


            </Box>

            <Box className="flex flex-col w-full h-full relative">
                {handleComponent(currentTab)}


                {/* dialog */}
                {openCreateStoryDialog && <CreateStoryComponent open={openCreateStoryDialog} closeDialog={() => setOpenCreateStoryDialog(false)}
                    handleContinue={handleCreateStory} />}
                {openEditStoryDialog && currentUserStoryData &&
                    <EditStoryComponent
                        open={openEditStoryDialog}
                        closeDialog={() => setOpenEditStoryDialog(false)}
                        handleContinue={handleUpdateStory}
                    />}
                {openDeleteStoryDialog &&
                    <DeleteStoryDialog
                        open={openDeleteStoryDialog}
                        handleClose={() => setOpenDeleteStoryDialog(false)}
                        handleContinue={() => handleDelete()}
                    />}

            </Box>
        </Box>
    )
}

function UseCasesComponent() {
    const { isGridViewEnabled, setIsGridViewEnabled } = useApplicationContext();
    const theme = useTheme();
    const [currentStoryFilter, setCurrentStoryFilter] = useState("all");

    let dataList = [
        {
            name: "Create a new user profile",
            description: {
                given: `Given a web page with a form for user registration. The user is on the sign up page.`,
                when: `When the user provides the required information such as name, email, password, and phone number.`,
                then: `Then the system creates a new user profile with the provided information.`
            },
            type: "usecase",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to register with the required information such as name, email, password, and phone number.",
                "The user should not be able to register if the required information is not provided.",
                "The user should not be able to register if the email address already exists."
            ]
        },
        {
            name: "Edit a user profile",
            description: {
                given: `Given a web page with a form for user profile editing.`,
                when: `When the user provides the required information such as name, email, password, and phone number.`,
                then: `Then the system updates the user profile with the provided information.`
            },
            type: "usecase",
            id: 2,
            status: "Approved",
            acceptanceCriteria: [
                "The user should be able to edit the user profile with the required information such as name, email, password, and phone number.",
                "The user should not be able to edit the user profile if the required information is not provided.",
                "The user should not be able to edit the user profile if the email address already exists."
            ]
        },
        {
            name: "Delete a user profile",
            description: {
                given: `Given a web page with a form for user profile deletion.`,
                when: `When the user clicks on the delete button.`,
                then: `Then the system deletes the user profile.`
            },
            type: "usecase",
            id: 3,
            status: "Under development",
            acceptanceCriteria: [
                "The user should be able to delete the user profile.",
                "The user should not be able to delete the user profile if the email address does not exist."
            ]
        }
    ];



    return (
        <Box className="flex flex-col w-full h-full">
            <Box className="w-full min-h-[7%] max-h-[7%] flex items-center gap-4 px-4 shadow-md backdrop-blur-md">
                <Typography>Use cases</Typography>
                <Avatar sx={{ width: "25px", height: "25px", bgcolor: theme.palette.common.white, color: purple[500], fontSize: "14px" }}>
                    {dataList?.length?.toString()}
                </Avatar>
            </Box>

            <Box className="flex w-full h-full">
                {dataList?.length === 0 ?
                    <Box className="grow flex flex-col gap-2 justify-center items-center ">
                        <Description sx={{ fontSize: font.size.fs_1 }} />
                        <Typography fontSize={font.size.fs_5}>No story</Typography>
                    </Box>
                    :
                    (
                        dataList
                            ?.filter((item) => currentStoryFilter === "all" ? true : item?.status?.toLowerCase() === currentStoryFilter)?.length === 0 ?
                            (
                                <Box className="grow flex flex-col gap-2 justify-center items-center ">
                                    <Description sx={{ fontSize: font.size.fs_1 }} />
                                    <Typography fontSize={font.size.fs_5}>No {currentStoryFilter} story</Typography>
                                </Box>
                            ) :

                            (
                                <Box className={` w-full h-fit flex ${isGridViewEnabled ? "flex-row justify-center flex-wrap gap-6" : "flex-col gap-1"}  px-4 py-4 `}>
                                    {
                                        dataList
                                            ?.filter((item) => currentStoryFilter === "all" ? true : item?.status?.toLowerCase() === currentStoryFilter)
                                            ?.map((item, index) => {
                                                return (
                                                    <Box
                                                        className={`bg-white overflow-hidden rounded-lg cursor-pointer border hover:shadow-xl transition-all ease-in-out duration-300 h-fit ${isGridViewEnabled ? "w-[98%]  min-w-[30%] sm:w-[47%] md:w-[31%] lg:w-[23%]" : "w-full"} `}
                                                        key={index}
                                                    // onClick={() => handleOpenModule(item)}
                                                    >
                                                        <Box className={`flex items-center gap-4 justify-between ${isGridViewEnabled ? "px-4 py-4" : "px-2 py-1"} `}>
                                                            <Description />
                                                            <Typography fontSize={font.size.fs_4} className="flex-1"> {item?.name}</Typography>
                                                            <IconButton>
                                                                <MoreVert />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                    }

                                </Box>
                            )
                    )
                }
            </Box>
        </Box>
    )
}

function StoriesComponent() {
    const { activeStory, setActiveStory, isGridViewEnabled, setIsGridViewEnabled } = useApplicationContext();
    const theme = useTheme();
    const [currentStoryFilter, setCurrentStoryFilter] = useState("all");

    let dataList = [
        {
            name: "Create a new user profile",
            description: {
                given: `Given a web page with a form to create a new user profile. The user is on the sign up page.`,
                when: `When the user enters the required information such as name, email, password, and phone number.`,
                then: `Then the system creates a new user profile with the required information such as name, email, password, and phone number.`
            },
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                "The user should not be able to create a new user profile if the required information is not provided.",
                "The user should not be able to create a new user profile if the email address already exists."
            ]
        },
        {
            name: "Log in to the service",
            description: {
                given: `Given a web page with a form to log in to the service.`,
                when: `When the user enters the email address and password.`,
                then: `Then the system logs the user in to the service with the email address and password.`
            },
            type: "story",
            id: 2,
            status: "Approved",
            acceptanceCriteria: [
                "The user should be able to log in to the service with the email address and password.",
                "The user should not be able to log in to the service if the email address or password is incorrect.",
                "The user should not be able to log in to the service if the email address does not exist."
            ]
        },
        {
            name: "Reset password",
            description: {
                given: `Given a web page with a form to reset the password.`,
                when: `When the user enters the email address.`,
                then: `Then the system resets the password with the email address.`
            },
            type: "story",
            id: 3,
            status: "Under development",
            acceptanceCriteria: [
                "The user should be able to reset the password with the email address.",
                "The user should not be able to reset the password if the email address does not exist."
            ]
        },
        {
            name: "Create a new project",
            description: {
                given: `Given a web page with a form to create a new project.`,
                when: `When the user enters the name and description of the project.`,
                then: `Then the system creates a new project with the required information such as name and description.`
            },
            type: "story",
            id: 6,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new project with the required information such as name and description.",
                "The user should not be able to create a new project if the required information is not provided.",
                "The user should not be able to create a new project if the project name already exists."
            ]
        },
        {
            name: "Edit a project",
            description: {
                given: `Given a web page with a form to edit a project.`,
                when: `When the user enters the name and description of the project.`,
                then: `Then the system edits the project with the required information such as name and description.`
            },
            type: "story",
            id: 7,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to edit the project with the required information such as name and description.",
                "The user should not be able to edit the project if the required information is not provided.",
                "The user should not be able to edit the project if the project name already exists."
            ]
        },
        {
            name: "Upload contract",
            description: {
                given: `A sales representative needs to upload a signed contract for a customer.`,
                when: `The representative selects the associated contact or opportunity, names the document, selects "Contract" as the document type, and uploads the file.`,
                then: `The system saves the document, linking it to the appropriate contact or opportunity, and auto-generates the upload date.`
            },
            type: "story",
            id: 8,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to upload a signed contract for a customer.",
                "The system should save the document, linking it to the appropriate contact or opportunity, and auto-generate the upload date."
            ]
        }
    ];

    const handleOpenStories = (data) => {
        setActiveStory(data);
    }


    return (
        <Box className="flex flex-col w-full h-full">
            <Box className="w-full min-h-[7%] max-h-[7%] flex items-center gap-4 px-4 shadow-md backdrop-blur-md">
                <Typography>User Stories</Typography>
                <Avatar sx={{ width: "25px", height: "25px", bgcolor: theme.palette.common.white, color: purple[500], fontSize: "14px" }}>
                    {dataList?.length?.toString()}
                </Avatar>
            </Box>

            <Box className="flex w-full h-full">
                {dataList?.length === 0 ?
                    <Box className="grow flex flex-col gap-2 justify-center items-center ">
                        <Description sx={{ fontSize: font.size.fs_1 }} />
                        <Typography fontSize={font.size.fs_5}>No story</Typography>
                    </Box>
                    :
                    (
                        dataList
                            ?.filter((item) => currentStoryFilter === "all" ? true : item?.status?.toLowerCase() === currentStoryFilter)?.length === 0 ?
                            (
                                <Box className="grow flex flex-col gap-2 justify-center items-center ">
                                    <Description sx={{ fontSize: font.size.fs_1 }} />
                                    <Typography fontSize={font.size.fs_5}>No {currentStoryFilter} story</Typography>
                                </Box>
                            ) :

                            (
                                <Box className={` w-full h-fit flex ${isGridViewEnabled ? "flex-row justify-center flex-wrap gap-6" : "flex-col gap-1"}  px-4 py-4 `}>
                                    {
                                        dataList
                                            ?.filter((item) => currentStoryFilter === "all" ? true : item?.status?.toLowerCase() === currentStoryFilter)
                                            ?.map((story, index) => {
                                                return (
                                                    <Box
                                                        className={`bg-white overflow-hidden rounded-lg border hover:shadow-xl transition-all ease-in-out duration-300
                                                         ${isGridViewEnabled ? "h-[115px] min-w-[30%] w-[98%] sm:w-[47%] md:w-[31%] lg:w-[23%]" : "w-full"} `}
                                                        key={index}
                                                    >
                                                        <StoryCard item={story} handleClick={() => handleOpenStories(story)} />
                                                    </Box>
                                                )
                                            })
                                    }

                                </Box>
                            )
                    )
                }
            </Box>
        </Box>
    )
}

function TableContainerComponent() {
    const { isGridViewEnabled, setIsGridViewEnabled, handleSnackbar, currentFolderData, setCurrentFolderData, userStoryList, setUserStoryList, currentUserStoryData, setCurrentUserStoryData, isShowStoryFilter, setIsShowStoryFilter, selectedUserStoryList, setSelectedUserStoryList } = useApplicationContext();
    const theme = useTheme();
    let timer;

    const [currentStoryFilter, setCurrentStoryFilter] = useState("all");
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [filteredList, setFilteredList] = useState([]);

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    let dataList = [
        {
            name: "Settings",
            description: {
                given: `Given a web page to modify user settings.`,
                when: `When the user changes notification preferences and privacy settings.`,
                then: `Then the system updates the user's settings accordingly.`
            },
            type: "module",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to update notification preferences.",
                "The user should be able to change privacy settings.",
                "The system should validate and save the updated settings."
            ]
        },
        {
            name: "Profile",
            description: {
                given: `Given a web page to view and edit user profile.`,
                when: `When the user updates their profile information such as name, email, and phone number.`,
                then: `Then the system saves the updated profile information.`
            },
            type: "module",
            id: 2,
            status: "Approved",
            acceptanceCriteria: [
                "The user should be able to view their profile information.",
                "The user should be able to edit and save their profile information.",
                "The system should validate the updated information."
            ]
        },
        {
            name: "Home",
            description: {
                given: `Given the home page of the application.`,
                when: `When the user navigates to the home page.`,
                then: `Then the system displays the dashboard with recent activities.`
            },
            type: "module",
            id: 3,
            status: "Under development",
            acceptanceCriteria: [
                "The user should see recent activities on the dashboard.",
                "The user should be able to navigate to other sections from the home page."
            ]
        },
        {
            name: "License",
            description: {
                given: `Given a web page to manage application members.`,
                when: `When the user adds or removes members.`,
                then: `Then the system updates the membership list accordingly.`
            },
            type: "module",
            id: 4,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to add new members.",
                "The user should be able to remove existing members.",
                "The system should validate member details."
            ]
        },
        {
            name: "Gallery",
            description: {
                given: `Given a web page to view and manage images.`,
                when: `When the user uploads or deletes images.`,
                then: `Then the system updates the gallery with the changes.`
            },
            type: "module",
            id: 5,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to upload images.",
                "The user should be able to delete images.",
                "The system should display all images in the gallery."
            ]
        },
        {
            name: "Chat",
            description: {
                given: `Given a web page to send and receive messages.`,
                when: `When the user sends a message.`,
                then: `Then the system delivers the message to the intended recipient.`
            },
            type: "module",
            id: 6,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to send and receive messages.",
                "The system should deliver messages in real-time."
            ]
        }
    ];

    let storiesList = [
        {
            // name: "Create a new user profile",
            // description: {
            //     given: `Given a web page with a form to create a new user profile. The user is on the sign up page.`,
            //     when: `When the user enters the required information such as name, email, password, and phone number.`,
            //     then: `Then the system creates a new user profile with the required information such as name, email, password, and phone number.`
            // },
            // type: "story",
            // id: 1,
            // status: "Draft",
            // acceptanceCriteria: [
            //     "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
            //     "The user should not be able to create a new user profile if the required information is not provided.",
            //     "The user should not be able to create a new user profile if the email address already exists."
            // ],

            customId: "US-001",
            text: "Implement user login functionality",
            notes: "Ensure compliance with security standards",
            priority: "High",
            createdBy: "abc@fgdfs.com",
            createdAt: "04-12-2024 18:26",
            updatedAt: "04-12-2024 18:26",
            assignedTo: "user@email",
            status: "Inprogress",
            acceptanceCriteria: [
                "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                "The user should not be able to create a new user profile if the required information is not provided.",
                "The user should not be able to create a new user profile if the email address already exists."
            ],
        },
        {
            customId: "US-002",
            text: "Implement user login functionality",
            notes: "Ensure compliance with security standards",
            priority: "High",
            createdBy: "abc@fgdfs.com",
            createdAt: "04-12-2024 18:26",
            updatedAt: "04-12-2024 18:26",
            assignedTo: "user@email",
            status: "Inprogress",
            acceptanceCriteria: [
                "The user should be able to log in to the service with the email address and password.",
                "The user should not be able to log in to the service if the email address or password is incorrect."
            ]
        },
        {
            customId: "US-003",
            text: "Reset Password",
            notes: "This user can reset the password",
            priority: "High",
            createdBy: "abc@fgdfs.com",
            createdAt: "04-12-2024 18:26",
            updatedAt: "04-12-2024 18:26",
            assignedTo: "user@email",
            status: "Inprogress",
            acceptanceCriteria: [
                "The user should be able to reset the password with the email address.",
                "The user should not be able to reset the password if the email address does not exist."
            ]
        },
        {
            customId: "US-004",
            text: "Create a new project",
            notes: "     Ensure compliance with security standards",
            priority: "High",
            createdBy: "abc@fgdfs.com",
            createdAt: "04-12-2024 18:26",
            updatedAt: "04-12-2024 18:26",
            assignedTo: "user@email",
            status: "Inprogress",
            acceptanceCriteria: [
                "The user should be able to create a new project with the required information such as name and description.",
                "The user should not be able to create a new project if the required information is not provided.",
                "The user should not be able to create a new project if the project name already exists."
            ]
        },
        {
            // name: "Edit a project",
            // description: {
            //     given: `Given a web page with a form to edit a project.`,
            //     when: `When the user enters the name and description of the project.`,
            //     then: `Then the system edits the project with the required information such as name and description.`
            // },
            // type: "story",
            // id: 7,
            // status: "Draft",
            // acceptanceCriteria: [
            //     "The user should be able to edit the project with the required information such as name and description.",
            //     "The user should not be able to edit the project if the required information is not provided.",
            //     "The user should not be able to edit the project if the project name already exists."
            // ],

            customId: "US-005",
            text: "Edit a project",
            notes: "Ensure compliance with security standards",
            priority: "High",
            createdBy: "abc@fgdfs.com",
            createdAt: "04-12-2024 18:26",
            updatedAt: "04-12-2024 18:26",
            assignedTo: "user@email",
            status: "Inprogress",
            acceptanceCriteria: [
                "The user should be able to edit the project with the required information such as name and description.",
                "The user should not be able to edit the project if the required information is not provided.",
                "The user should not be able to edit the project if the project name already exists."
            ]
        },
        {
            // name: "Upload contract",
            // description: {
            //     given: `A sales representative needs to upload a signed contract for a customer.`,
            //     when: `The representative selects the associated contact or opportunity, names the document, selects "Contract" as the document type, and uploads the file.`,
            //     then: `The system saves the document, linking it to the appropriate contact or opportunity, and auto-generates the upload date.`
            // },
            // type: "story",
            // id: 8,
            // status: "Draft",
            // acceptanceCriteria: [
            //     "The user should be able to upload a signed contract for a customer.",
            //     "The system should save the document, linking it to the appropriate contact or opportunity, and auto-generate the upload date."
            // ]

            customId: "US-006",
            text: "Upload contract",
            notes: "Ensure compliance with security standards",
            priority: "High",
            createdBy: "abc@fgdfs.com",
            createdAt: "04-12-2024 18:26",
            updatedAt: "04-12-2024 18:26",
            assignedTo: "user@email",
            status: "Inprogress",
            acceptanceCriteria: [
                "The user should be able to upload a signed contract for a customer.",
                "The system should save the document, linking it to the appropriate contact or opportunity, and auto-generate the upload date."
            ]
        }
    ];

    let dashboardDataList = [{
        name: "Modules",
        // count: dataList?.length
    },
    {
        name: "Stories",
        // count: storiesList?.length
    }];



    const handleChangeSearch = (e) => {
        // setSearchText(e.target.value);
        clearTimeout(timer);
        setIsLoading(true);

        timer = setTimeout(() => {
            setSearchText(e.target.value);
            setIsLoading(false);
        }, 1000)
    };


    useEffect(() => {
        let filteredList = userStoryList;

        if (searchText) {
            filteredList = userStoryList?.filter((item) => item.text.toLowerCase()?.includes(searchText.toLowerCase()));
        }

        setFilteredList(filteredList);
    }, [searchText, userStoryList]);

    return (
        <Box className="flex flex-col w-full h-full">

            {isShowStoryFilter && (false ? (
                <Box className="flex items-center gap-2 py-1 px-2">
                    <Skeleton variant="rounded" width={"100%"} height={42} animation="wave" className="max-w-56" />
                    <Skeleton variant="rounded" width={"100%"} height={42} animation="wave" className="flex-1" />
                </Box>
            ) : (
                <Box className="w-full flex items-center justify-between gap-2 pb-2 px-2 ">
                    <TextField fullWidth disabled={userStoryList?.length === 0} type="search" variant="standard" placeholder="Search keyword" className="max-w-[20%]" defaultValue={searchText} onChange={handleChangeSearch} />
                    <Box className=" box-border flex items-center gap-2 overflow-x-auto ">
                        <Button color="dark" disabled={userStoryList?.length === 0} endIcon={<ArrowDropDown />}>Created By</Button>
                        <Button color="dark" disabled={userStoryList?.length === 0} endIcon={<ArrowDropDown />}>Coverage Status</Button>
                        <Button color="dark" disabled={userStoryList?.length === 0} endIcon={<ArrowDropDown />}>Review Status</Button>
                        <Button color="dark" disabled={userStoryList?.length === 0} endIcon={<ArrowDropDown />}>Define Type</Button>
                        <Button color="dark" disabled={userStoryList?.length === 0} endIcon={<ArrowDropDown />}>Assigned To</Button>
                        <Button color="dark" disabled={userStoryList?.length === 0} endIcon={<ArrowDropDown />}>Moscow</Button>
                        <Button color="dark" disabled={userStoryList?.length === 0} endIcon={<ArrowDropDown />}>Category</Button>
                        <LightTooltip title="Clear Filter">
                            <IconButton disabled={userStoryList?.length === 0}>
                                <Close />
                            </IconButton>
                        </LightTooltip>
                    </Box>
                </Box>
            ))}

            <Box className="flex w-full h-full">
                {isLoading ? (
                    <Box className="grow flex flex-col gap-2 justify-center items-center ">
                        <CircularProgress />
                        <Typography fontSize={font.size.fs_5}>Loading...</Typography>
                    </Box>
                ) : (

                    userStoryList?.length === 0 ?
                        <Box className="grow flex flex-col gap-2 justify-center items-center ">
                            <Description sx={{ fontSize: font.size.fs_1 }} />
                            <Typography fontSize={font.size.fs_5}>No Userstory</Typography>
                        </Box>
                        :
                        (
                            <Box className=" flex h-full w-full px-0 py-0 overflow-x-auto">
                                <UserStoryTable dataList={filteredList} />
                            </Box>
                        )
                )}
            </Box>

        </Box>
    )
}

function DashboardContainerComponent() {
    const { isGridViewEnabled, setIsGridViewEnabled, } = useApplicationContext();
    const theme = useTheme();

    let dashboardDataList = [{
        name: "Modules",
        count: 6
    },
    {
        name: "Stories",
        count: 10
    }];

    return (
        <Box className="flex flex-col w-full h-full">

            <Box className="flex justify-center flex-wrap gap-4 w-full h-full">
                {dashboardDataList?.map((data, index) => (
                    <Box key={index} className="flex flex-col rounded-xl px-4 py-4 w-56 h-56" bgcolor={theme.palette.common.white}>
                        <Box key={index} className="flex-1 flex items-center justify-center" >
                            <Typography fontSize={font.size.fs_1} >{data?.count}</Typography>
                        </Box>
                        <Typography fontSize={font.size.fs_5} className="opacity-90">{data?.name}</Typography>
                    </Box>
                ))}
            </Box>

        </Box>
    )
}

