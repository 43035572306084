import { Add, ArrowRightAlt, AutoAwesomeOutlined, Close, Delete, Edit, FileOpenOutlined, KeyboardArrowDownOutlined, PlayCircleOutline } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Chip, Divider, IconButton, Tab, Tabs, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useApplicationContext } from "../../context/ApplicationContext";
import { useState } from "react";
import { font } from "../../styles/font";
import { blue, brown, green, grey, red } from "@mui/material/colors";
import { CustomAccordionComponent, CustomAccordionComponentDetails, CustomAccordionComponentSummary, LightTooltip } from "../styled/CustomComponent";

class STORY_STATUS {
    static DRAFT = "Draft";
    static APPROVED = "Approved";
    static UNDER_DEVELOPMENT = "Under development";
    static CLOSED = "Closed";
}

function MarketplacePreview() {
    const { currentUserStory, setCurrentUserStory, openStoryPreview, setOpenStoryPreview, toggleChatWindow, setToggleChatWindow } = useApplicationContext();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const theme = useTheme();

    const [tabValue, setTabValue] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCloseStory = (story) => {
        setCurrentUserStory(null);
        setOpenStoryPreview(false);
    }

    return (
        <Box className="grow flex justify-center border absolute left-0 top-0 right-0 bottom-0 rounded-lg  ">
            {/* wrapper container */}
            <Box className="flex flex-col gap-4 grow w-full h-full  p-4 pt-0   overflow-y-auto" bgcolor={theme.palette.primary.layout_col}>

                {/* header */}
                <Box className="flex flex-col border-b sticky top-0 py-2 gap-2 backdrop-blur-sm z-50 bg-blue-500" bgcolor={theme.palette.primary.layout_col}>
                    {/* top section */}
                    <Box className="flex items-center justify-between w-full" >

                        <Box className="flex flex-1 items-center justify-between px-2 py-2" >
                            <Typography fontSize={font.size.fs_3}>{currentUserStory?.name}</Typography>
                        </Box>

                        <IconButton size="small" onClick={handleCloseStory}>
                            <Close />
                        </IconButton>
                    </Box>

                    {/* bottom section */}
                    <Box className="flex items-center  justify-between w-full" >

                        <Box className="w-fit border rounded-lg px-4">
                            <Tabs
                                value={tabValue}
                                onChange={handleChangeTab}
                            >
                                <Tab value={0} label="Details" />
                                <Tab value={1} label="Scenarios" />
                            </Tabs>
                        </Box>

                        {tabValue === 0 && <Box className="flex items-center ">
                            <Button variant="contained" startIcon={<Add />} >Use Story</Button>
                        </Box>}

                    </Box>
                </Box>


                {/* main container */}
                <Box className="flex flex-1 h-full ">
                    {tabValue === 0 && <UserStoryComponent />}
                    {tabValue === 1 && <ScenarioComponent />}
                </Box>

            </Box>
        </Box>
    )
}

export default MarketplacePreview


function KeyValue({ label, value }) {
    return (
        <Box className="flex items-center gap-2 w-full ">
            <Typography className="" fontSize={font.size.fs_4}>{label}:</Typography>
            <Box className="flex-1">{value}</Box>
        </Box>
    )
}

function LabelTypography(props) {
    return <Typography className="flex-1" fontSize={font.size.fs_5} {...props}>{props?.label}:</Typography>
}

function UserStoryComponent({ item }) {
    const { currentUserStory } = useApplicationContext();

    const description = {
        "given": `A sales representative needs to upload a signed contract for a customer.`,
        "when": `The representative selects the associated contact or opportunity, names the document, selects "Contract" as the document type, and uploads the file.`,
        "then": `The system saves the document, linking it to the appropriate contact or opportunity, and auto-generates the upload date.`
    }

    return (
        <Box className=" flex gap-4 " >
            {/* left section */}
            <Box className="flex-1 flex flex-col gap-4">


                <Box className="flex flex-col gap-2">
                    <Typography fontSize={font.size.fs_4}>Description:</Typography>

                    <Box className="flex flex-col gap-2 px-4">
                        <Box className="flex flex-col gap-1">

                            <LabelTypography label={"As a user"} />
                            <Typography variant="body2" className="font-bold">{description?.given}</Typography>
                        </Box>
                        <Box className="flex flex-col gap-1">
                            <LabelTypography label={"I want to be able to"} />
                            <Typography variant="body2" className="font-bold">{description?.when}</Typography>
                        </Box>
                        <Box className="flex flex-col gap-1">
                            <LabelTypography label={"To achieve this"} />
                            <Typography variant="body2" className="font-bold">{description?.then}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box className="flex flex-col gap-2">
                    <Typography fontSize={font.size.fs_4}>Acceptance Criteria:</Typography>
                    <Box className="flex flex-col gap-0 px-4">
                        {currentUserStory?.acceptanceCriteria?.map((criteria, index) => (
                            <Box key={index} className="flex items-center gap-2">
                                <ArrowRightAlt />
                                <Typography variant="body2" >{criteria}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>

            </Box>

        </Box>
    )
}

function ScenarioComponent({ item }) {
    const { currentUserStory } = useApplicationContext();
    const theme = useTheme();

    const [toggleChatWindow, setToggleChatWindow] = useState(false);
    const [expandedSteps, setExpandedSteps] = useState([]);


    // "Navigate to the login page.",
    // "Click on the login button.",
    // "Enter the username.",
    // "Enter the password.",
    // "Click on the continue button.",

    const dataList = [
        {
            id: 1,
            name: "Navigate to the login page",
            testCase: "Navigate to the login page",
            scenarios: [
                {
                    scenario: "Valid URL is entered in the browser",
                    expected: "Login page is displayed",
                    stepDef: "driver.get('validURL')"
                },
                {
                    scenario: "Invalid URL is entered in the browser",
                    expected: "Error message is displayed",
                    stepDef: "driver.get('invalidURL')"
                },
                {
                    scenario: "No URL is entered in the browser",
                    expected: "Error message is displayed",
                    stepDef: "driver.get('')"
                },
            ]
        },
        {
            id: 2,
            name: "Click on the login button",
            testCase: "Click on the login button",
            scenarios: [
                {
                    scenario: "Valid username and password are entered",
                    expected: "Dashboard page is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('validUsername'); driver.findElement(By.id('password')).sendKeys('validPassword'); driver.findElement(By.id('loginButton')).click()"
                },
                {
                    scenario: "Invalid username and password are entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('invalidUsername'); driver.findElement(By.id('password')).sendKeys('invalidPassword'); driver.findElement(By.id('loginButton')).click()"
                },
                {
                    scenario: "No username and password are entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('loginButton')).click()"
                },
                {
                    scenario: "Only username is entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('validUsername'); driver.findElement(By.id('loginButton')).click()"
                },
                {
                    scenario: "Only password is entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('validPassword'); driver.findElement(By.id('loginButton')).click()"
                },
            ]
        },
        {
            id: 3,
            name: "Enter the username",
            testCase: "Enter the username",
            scenarios: [
                {
                    scenario: "Valid username is entered",
                    expected: "Username is accepted",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('validUsername')"
                },
                {
                    scenario: "Invalid username is entered (special characters, numbers)",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('invalidUsername!123')"
                },
                {
                    scenario: "Username is left blank",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('')"
                },
                {
                    scenario: "Username is too long",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('averyverylongusername')"
                },
                {
                    scenario: "Username is too short",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('a')"
                },
            ]
        },
        {
            id: 4,
            name: "Enter the password",
            testCase: "Enter the password",
            scenarios: [
                {
                    scenario: "Valid password is entered",
                    expected: "Password is accepted",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('validPassword')"
                },
                {
                    scenario: "Invalid password is entered (special characters, numbers)",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('invalidPassword!123')"
                },
                {
                    scenario: "Password is left blank",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('')"
                },
                {
                    scenario: "Password is too long",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('averyverylongpassword')"
                },
                {
                    scenario: "Password is too short",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('short')"
                },
            ]
        },
        {
            id: 5,
            name: "Click on the continue button",
            testCase: "Click on the continue button",
            scenarios: [
                {
                    scenario: "Valid username and password are entered",
                    expected: "Dashboard page is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('validUsername'); driver.findElement(By.id('password')).sendKeys('validPassword'); driver.findElement(By.id('continueButton')).click()"
                },
                {
                    scenario: "Invalid username and password are entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('invalidUsername'); driver.findElement(By.id('password')).sendKeys('invalidPassword'); driver.findElement(By.id('continueButton')).click()"
                },
                {
                    scenario: "No username and password are entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('continueButton')).click()"
                },
                {
                    scenario: "Only username is entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('username')).sendKeys('validUsername'); driver.findElement(By.id('continueButton')).click()"
                },
                {
                    scenario: "Only password is entered",
                    expected: "Error message is displayed",
                    stepDef: "driver.findElement(By.id('password')).sendKeys('validPassword'); driver.findElement(By.id('continueButton')).click()"
                },
            ]
        }
    ]



    return (
        <Box className=" flex flex-col gap-2 h-full w-full " >


            {
                dataList?.map((item, index) => (
                    <CustomAccordionComponent key={index} expanded={expandedSteps.includes(item.id)}    >
                        <CustomAccordionComponentSummary>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", width: "100%" }}>
                                <Box className="flex items-center gap-1 flex-1">
                                    <IconButton
                                        sx={{ color: expandedSteps.includes(item.id) && "white", background: expandedSteps.includes(item.id) && theme.palette.primary.main, "&:hover": { background: expandedSteps.includes(item.id) && theme.palette.primary.main } }}
                                        size="small"
                                        // color="secondary"
                                        onClick={() => { setExpandedSteps(expandedSteps.includes(item.id) ? expandedSteps.filter(id => id !== item.id) : [...expandedSteps, item.id]) }}
                                    >
                                        <KeyboardArrowDownOutlined />
                                    </IconButton>
                                    <Box className="flex flex-col gap-2 flex-1 ">
                                        <Typography fontSize={font.size.fs_5} className="">{item.name}</Typography>
                                        {/* <Typography fontSize={font.size.fs_5} color="secondary" className=" bg-white px-2 py-1 rounded-lg">{item.testCase} sjdfl jlskfj lkdsj flk jdslkfj lkdsjf dslkf dsklfj lkdsj f</Typography> */}
                                    </Box>
                                </Box>

                                <Box className="flex items-center">
                                    <LightTooltip title={`Total Scenarios : ${item?.scenarios?.length}`} arrow>
                                        <Avatar sx={{ width: "22px", height: "22px", fontSize: font.size.fs_5, color: "inherit", background: "white", mr: "6px", border: `2px solid ${grey[600]}` }} >{item?.scenarios?.length} </Avatar>
                                    </LightTooltip>
                                </Box>
                            </Box>
                        </CustomAccordionComponentSummary>
                        <CustomAccordionComponentDetails >
                            <Box className="flex flex-col gap-2">

                                {
                                    item?.scenarios?.map((item, index) => (
                                        <Box key={index} className="flex items-center gap-2  px-2 py-1 border rounded-lg">
                                            <Box key={index} className="flex flex-col flex-1 " >
                                                <LightTooltip title={item.scenario}>
                                                    <Typography fontSize={font.size.fs_5} className="w-fit" >{item.scenario}</Typography>
                                                </LightTooltip>
                                                <LightTooltip title={item.stepDef} >
                                                    <Typography fontSize={font.size.fs_5} color="primary" className="w-fit"  >{item.stepDef}</Typography>
                                                </LightTooltip>
                                            </Box>

                                        </Box>
                                    ))
                                }
                            </Box>
                        </CustomAccordionComponentDetails>
                    </CustomAccordionComponent>
                ))
            }

        </Box>
    )
}