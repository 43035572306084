
import { HttpStatusCode } from "axios";
import { useApplicationContext } from "../../context/ApplicationContext";
import { Severity } from "../../constant/ApplicationVariables";

/**
 * Custom hook for handling HTTP error responses.
 * @returns {function} errorHandler - Function to handle HTTP error responses.
 */
export default function useErrorHandler() {
  // Accessing necessary context for displaying global snackbar messages
  const { setGLobalSnackBar } = useApplicationContext();

  /**
   * Handles HTTP error responses based on status codes and triggers appropriate actions.
   * @param {object} response - HTTP response object containing status and data.
   * @param {function} setLoader - Optional function to set loader state.
   * @param {function} setOpen - Optional function to set open state for dialogs.
   * @param {function} handleClose - Optional function to handle closing actions.
   */
  const errorHandler = (response, setLoader, setOpen, handleClose) => {
    console.log(response, setLoader, 'customHook'); // Logging the response and custom hook message

    // Handling different HTTP status codes with corresponding severity levels
    if (response?.message === "Network Error") {
      handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    } else if (response.status === HttpStatusCode.Forbidden) {
      handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    } else if (response.status === HttpStatusCode.Ok) {
      handleSnackbar(response, Severity.success, setLoader, setOpen, handleClose);
    } else if (response.status === HttpStatusCode.Created) {
      handleSnackbar(response, Severity.success, setLoader, setOpen, handleClose);
    } else if (!response.status) {
      handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    } else if (response.status === HttpStatusCode.NotFound) {
      handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    } else if (response.status === HttpStatusCode.BadRequest) {
      handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    } else if (response.status === HttpStatusCode.BadGateway) {
      handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    } else if (response.status === HttpStatusCode.TooManyRequests) {
      handleSnackbar(response, Severity.warning, setLoader, setOpen, handleClose);
    } else if (response.status === HttpStatusCode.Conflict) {
      handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    } else if (response.status === HttpStatusCode.InternalServerError) {
      handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    } else if (response.status === HttpStatusCode.GatewayTimeout) {
      handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    } else {
      handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    }

    // if (response.status === HttpStatusCode.Ok) {
    //   handleSnackbar(response, Severity.success, setLoader, setOpen, handleClose);
    // }
    // if (response.status === HttpStatusCode.NotFound) {
    //   handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    // }
    // if (response.status === HttpStatusCode.BadRequest) {
    //   handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    // }
    // if (response.status === HttpStatusCode.BadGateway) {
    //   handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    // }
    // if (response.status === HttpStatusCode.TooManyRequests) {
    //   handleSnackbar(response, Severity.warning, setLoader, setOpen, handleClose);
    // }
    // if (response.status === HttpStatusCode.Conflict) {
    //   handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    // }
    // if (response.status === HttpStatusCode.InternalServerError) {
    //   handleSnackbar(response, Severity.error, setLoader, setOpen, handleClose);
    // }
  };

  /**
   * Displays a global snackbar message based on the severity and performs optional actions.
   * @param {object} response - HTTP response object containing status and data.
   * @param {string} severity - Severity level for the snackbar (success, error, warning).
   * @param {function} setLoader - Optional function to set loader state.
   * @param {function} setOpen - Optional function to set open state for dialogs.
   * @param {function} handleClose - Optional function to handle closing actions.
   */
  const handleSnackbar = (response, severity, setLoader, setOpen, handleClose) => {
    // Setting global snackbar with message title and severity
    setGLobalSnackBar({
      open: true,
      title: response?.data?.message || response?.data?.messages || response?.message || (typeof response?.data === "string" && response),
      severity: severity,
    });

    // Performing optional actions based on provided functions
    setLoader && setTimeout(() => setLoader(false), 1000);   // Close loader if setLoader function is provided
    setOpen && setOpen(false);       // Close dialog if setOpen function is provided
    handleClose && handleClose();    // Execute handleClose function if provided
  };

  return errorHandler; // Returning the errorHandler function for handling HTTP errors
}
