import { Route, Routes } from "react-router-dom";
import StoryContainer from "../components/story";
import MarketplaceContainer from "../components/marketplace/index.js";
import ExecutionContainer from "../components/execution/index.js";
import ProjectsLayout from "../components/projects/index.js";
import ProjectsContainer from "../components/projects/ProjectsContainer.js";
import RequirementsContainer from "../components/projects/RequirementsContainer.js";
import ProtectedRoute from "./ProtectedRoute.js";

// export const router = createBrowserRouter([
//     // {
//     //     layout: MainLayout,
//     //     routes: [
//     //         {
//     //             name: 'home',
//     //             title: 'Home page',
//     //             component: Home,
//     //             path: '/home'
//     //         },
//     //     ],

//     // },
//     {
//         path: "/",
//         element: <div>Home</div>,
//     },
//     {
//         path: "/story",
//         element: <StoryContainer />
//     },
//     {
//         path: "/marketplace",
//         element: <div>Market place</div>,
//     },
// ]);

function RoutesComponent() {
    return (
        <Route element={<ProtectedRoute isAuthenticated={true} />} >
            <Route exact path='/' element={<div>Home</div>} key='home' />
            <Route path='/projects' element={<ProjectsContainer />} key='project' />
            <Route path='/requirements' element={<RequirementsContainer />} key='requirements' />
            <Route path='/story' element={<StoryContainer />} key='story' />
            <Route path='/marketplace' element={<MarketplaceContainer />} key='marketplace' />
            <Route path='/execution' element={<ExecutionContainer />} key='execution' />
        </Route>
    )
}

export default RoutesComponent
