import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { font } from '../../styles/font';
import { useEffect, useState } from 'react';
import { useApplicationContext } from '../../context/ApplicationContext';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import { HttpStatusCode } from 'axios';
import { addProject_API, getProjectCategoryList_API, getProjectList_API, getProjectSubcategoryList_API } from '../../api/controller/ProjectController';
import { Severity } from '../../constant/ApplicationVariables';

export default function CreateProjectDrawer({ open, handleClose }) {
    const { setGlobalLoader, setProjectList, handleSnackbar, } = useApplicationContext();
    const errorHandler = useErrorHandler();

    const [projectData, setProjectData] = useState(null);
    const [activeProjectCategory, setActiveProjectCategory] = useState(null);
    const [activeProjectSubcategory, setActiveProjectSubcategory] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);

    // let categoryList = [
    //     { "name": "Technology" },
    //     { "name": "Finance" },
    //     { "name": "Healthcare" }
    // ];

    // let subCategoryList = [
    //     { "name": "Software" },
    //     { "name": "Hardware" },
    //     { "name": "Banking" },
    //     { "name": "Investment" }
    // ]

    const toggleDrawer = () => {
        handleClose();
    };

    // const handleChangeProjectCategory = (event) => {
    //     setActiveProjectCategory(event.target.value);
    // }

    const handleChange = (e) => {
        if (e.target.name === "categoryName" || e.target.name === "subCategoryName") {
            setProjectData({ ...projectData, [e.target.name]: e.target.value?.name });
        } else {
            setProjectData({ ...projectData, [e.target.name]: e.target.value });
        }
    }

    // const handleChangeProjectSubcategory = (event) => {
    //     setActiveProjectSubcategory(event.target.value);
    // }


    const handleCreateProject = async () => {
        if (!projectData?.name || !projectData?.description || !projectData?.categoryName || !projectData?.subCategoryName) {
            return handleSnackbar("Please fill all the fields", Severity.error)
        }

        try {
            setIsLoading(true);

            let body = { ...projectData };

            const response = await addProject_API(body);

            if (response.status === HttpStatusCode.Ok) {
                handleGetProjectList();
                handleClose();
                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {
                setIsLoading(false);
            }, 1000);

            console.log(response, "projectreponse data");
        } catch (error) {
            console.log(error, "projectreponse errorr");
        }
    }

    const handleGetProjectList = async () => {
        try {
            setGlobalLoader(true);

            const response = await getProjectList_API();

            if (response.status === HttpStatusCode.Ok) {
                setProjectList(response.data);
                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, "projectreponse errorr")
        }
    }

    const handleGetCategoryList = async () => {
        try {
            setIsLoading(true);

            const response = await getProjectCategoryList_API();

            if (response.status === HttpStatusCode.Ok) {
                setCategoryList(response.data);
                setProjectData({ "name": "", "description": "", "categoryName": response.data[0]?.name, "subCategoryName": "" });
                // setProjectData({ ...projectData, "categoryName": response.data[0]?.name });

                // handleSnackbar(response.message, Severity.success);
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {       
                setIsLoading(false);
            }, 1000);

            console.log(response, "categoryreponse data")
        } catch (error) {
            console.log(error, "categoryreponse errorr")
        }
    }

    const handleGetsubCategoryList = async () => {
        try {
            // setGlobalLoader(true);

            let query = `parentName=${projectData?.categoryName}`;

            const response = await getProjectSubcategoryList_API(query);

            if (response.status === HttpStatusCode.Ok) {
                setSubCategoryList(response.data);
                setProjectData({ ...projectData, "subCategoryName": response.data[0]?.name });

                // handleSnackbar(response.message, Severity.success);
            }

            if (response) {
                errorHandler(response);
            }

            // setTimeout(() => {       
            //     setGlobalLoader(false);
            // }, 1000);

            console.log(response, "subcategoryreponse data")
        } catch (error) {
            console.log(error, "subcategoryreponse errorr")
        }
    }

    useEffect(() => {
        handleGetCategoryList();
    }, []);

    useEffect(() => {
        if (projectData?.categoryName) {
            handleGetsubCategoryList();
        }
    }, [projectData?.categoryName]);



    console.log(projectData, "projectData1")

    const DrawerList = (
        <Box sx={{ width: 420 }} role="presentation" className="flex flex-col h-full">
            <Box className="flex items-center justify-between px-4 py-2 border-b-2">
                <Typography fontSize={font.size.fs_4}>Create Project</Typography>
                <IconButton onClick={() => toggleDrawer()}>
                    <Close />
                </IconButton>
            </Box>
            <Box className="flex-1 flex flex-col gap-4 px-4 py-4 ">
                <TextField required name="name" label="Project Name" sx={{ fontSize: font.size.fs_4 }} value={projectData?.name} onChange={handleChange} />
                <TextField required name="description" label="Project Description" multiline minRows={4} sx={{ fontSize: font.size.fs_4 }} value={projectData?.description} onChange={handleChange} />

                <Box>
                    <Box className="flex items-center justify-between gap-2 rounded-xl px-2 py-1 bg-white">
                        <Typography>Category</Typography>
                        <Select size="small"
                            className="w-44 h-8"
                            variant="outlined"
                            name="categoryName"
                            value={projectData?.categoryName}
                            displayEmpty
                            renderValue={() => projectData?.categoryName}
                            onChange={handleChange}
                        >
                            {
                                categoryList?.map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={index}>
                                            {item?.name}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </Box>

                    <Box className="flex items-center justify-between gap-2 rounded-xl px-2 py-1 bg-white">
                        <Typography>SubCategory</Typography>
                        <Select size="small"
                            className="w-44 h-8"
                            variant="outlined"
                            name="subCategoryName"
                            value={projectData?.subCategoryName}
                            displayEmpty
                            renderValue={() => projectData?.subCategoryName}
                            onChange={handleChange}
                        >
                            {
                                subCategoryList?.map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={index}>
                                            {item?.name}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </Box>
                </Box>
            </Box>

            <Box className="flex flex-col gap-4 px-4 py-4 border-t-2">
                <Button variant="contained" disabled={isLoading || !projectData?.name || !projectData?.description} onClick={handleCreateProject}>Create</Button>
                <Button variant="outlined" color="error" disabled={isLoading || !projectData?.name || !projectData?.description} onClick={() => toggleDrawer()}>Discard</Button>
            </Box>

        </Box>
    );

    return (
        <Box>
            <Drawer open={open} anchor="right">
                {DrawerList}
            </Drawer>
        </Box>
    );
}