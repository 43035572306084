import { AccountCircleOutlined, Add, AddOutlined, ArrowBack, ArrowLeft, ArrowRightAlt, AutoAwesome, AutoAwesomeOutlined, Delete, DeleteOutline, Description, DownloadOutlined, Edit, EditOutlined, FilterAltOffOutlined, FilterAltOutlined, FolderOutlined, Home, HomeOutlined, MoreHoriz, UploadOutlined } from '@mui/icons-material';
import { Avatar, Box, Breadcrumbs, Button, Chip, CircularProgress, Divider, Fab, Icon, IconButton, Typography, useTheme } from '@mui/material'
import { useApplicationContext } from '../../context/ApplicationContext';
import { useEffect, useState } from 'react';
import { blue, grey, purple, red } from '@mui/material/colors';
import { font } from '../../styles/font';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ChatWindow from './ChatWindow';
import ProjectViewContainer from './RequirementMainContainer';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import Treeview from '../common/Treeview';
import { LightTooltip } from '../styled/CustomComponent';
import SidebarToggleButton from '../common/SidebarToggleButton';
import ChatWindowToggleButton from '../common/ChatWindowToggleButton';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import RequirementMainContainer from './RequirementMainContainer';
import CreateModuleDialog from '../dialog/CreateModuleDialog';
import { HttpStatusCode } from 'axios';
import { Severity } from '../../constant/ApplicationVariables';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import { addModule_API, deleteModule_API, updateModule_API } from '../../api/controller/ModuleController';
import { getFolderStructureList_API } from '../../api/controller/RequirementController';
import EditModuleDialog from '../dialog/EditModuleDialog';
import DeleteModuleDialog from '../dialog/DeleteModuleDialog';

export default function RequirementsContainer() {
    const { toggleChatWindow, setToggleChatWindow, toggleFolderSidebar, setToggleFolderSidebar, folderStructureList, setFolderStructureList, activeProject, setActiveProject, activeModule, setActiveModule, activeStory, setActiveStory, handleSnackbar, setGlobalLoader, currentFolderData, setCurrentFolderData, isUpdateCurrentFolderData, setIsUpdateCurrentFolderData, isUpdateFolderStructure, setIsUpdateFolderStructure } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();

    const [loading, setLoading] = useState(false);
    const [openCreateModuleDialog, setOpenCreateModuleDialog] = useState(false);
    const [openEditModuleDialog, setOpenEditModuleDialog] = useState(false);
    const [openDeleteModuleDialog, setOpenDeleteModuleDialog] = useState(false);

    console.log(folderStructureList, "folderStructureList")

    const folderList = [
        {
            id: "1",
            name: "Trunk2tale",
            type: "project",
            moduleCount: 2,
            children: [
                {
                    id: "m1",
                    name: "Settings",
                    type: "module",
                    userStoryCount: 5,

                },
                {
                    id: "m2",
                    name: "Profile",
                    type: "module",
                    userStoryCount: 5,

                },
            ],
        },
    ];

    const handleGetFolderStructureList = async (project) => {
        try {
            // setGlobalLoader(true);

            let query = `name=${project?.name}`

            const response = await getFolderStructureList_API(query);

            if (response.status === HttpStatusCode.Ok) {
                // setFolderStructureList(response.data);
                setFolderStructureList([{ ...response.data }]);
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {
                // setGlobalLoader(false);
            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, "projectreponse errorr")
        }
    }

    const handleCreateModule = async (moduleData) => {
        try {
            setLoading(true);

            let body = {
                ...moduleData,
                "projectName": currentFolderData?.data?.name,
            }

            const response = await addModule_API(body);
            console.log(response, "response handleCreateModule")

            if (response) {
                errorHandler(response, setGlobalLoader);
            }

            if (response.status === HttpStatusCode.Ok) {
                // handleGetFolderStructureList(currentFolderData?.data);
                setIsUpdateFolderStructure(true);
                setOpenCreateModuleDialog(false);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleUpdateModule = async (moduleData) => {
        try {
            setLoading(true);

            let body = {
                "projectName": currentFolderData?.parent?.data?.name,
                "currentName": currentFolderData?.data?.name,
                "newName": moduleData?.name,
                "description": moduleData?.description
            }

            const response = await updateModule_API(body);
            console.log(response, "response handleUpdateModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                // handleGetFolderStructureList(currentFolderData?.parent?.data);
                setIsUpdateFolderStructure(true);
                setOpenEditModuleDialog(false);
                setIsUpdateCurrentFolderData(true);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleDeleteModule = async () => {
        try {
            setLoading(true);

            let body = {
                "projectName": currentFolderData?.parent?.data?.name,
                "name": currentFolderData?.data?.name,
            }

            const response = await deleteModule_API(body);
            console.log(response, "response handleDeleteModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                // handleGetFolderStructureList(currentFolderData?.parent?.data);
                setIsUpdateFolderStructure(true);
                setOpenDeleteModuleDialog(false);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }


    return (
        <Box className=" flex gap-0 relative h-full w-full box-border overflow-y-auto overflow-x-hidden">

            {/* folder sidebar */}
            <Box
                className={`flex flex-col h-full w-0 relative border-r border-gray-300 `}
                sx={{
                    minWidth: toggleFolderSidebar ? "20vw" : 0,
                    maxWidth: toggleFolderSidebar ? "20vw" : 0,
                    transition: "width 0.5s ease-in-out",
                }}
                bgcolor={theme.palette.primary.layout_col}
            >
                <Box className="w-full min-h-[7%] flex items-center justify-around sticky top-0 z-10 backdrop-blur-md">
                    {/* <IconButton >
                        <FolderOutlined />
                    </IconButton> */}
                    <LightTooltip title="Create Module" >
                        <IconButton disabled={currentFolderData?.data?.type !== "project"} onClick={() => setOpenCreateModuleDialog(true)}>
                            <AddOutlined />
                        </IconButton>
                    </LightTooltip>
                    <LightTooltip title="Edit Module">
                        <IconButton disabled={currentFolderData?.data?.type === "project"} onClick={() => setOpenEditModuleDialog(true)}>
                            <EditOutlined />
                        </IconButton>
                    </LightTooltip>
                    <LightTooltip title="Delete Module">
                        <IconButton disabled={currentFolderData?.data?.type === "project"} onClick={() => setOpenDeleteModuleDialog(true)}>
                            <DeleteOutline />
                        </IconButton>
                    </LightTooltip>
                </Box>

                <Box className=" flex w-full h-full shadow-sm">
                    {
                        (loading || isUpdateFolderStructure) ?
                            <Box className=" flex flex-col items-center justify-center gap-1 w-full h-full ">
                                <CircularProgress />
                                <Typography>Loading...</Typography>
                            </Box>
                            :
                            (
                                <Box className=" flex w-full h-full ">
                                    <Treeview dataList={folderStructureList} />
                                </Box>
                            )
                    }
                </Box>

            </Box>

            {/* main container */}
            <Box
                className="flex flex-col h-full flex-1 pt-1 z-10 overflow-hidden"
                bgcolor={theme.palette.primary.layout_col}
                sx={{
                    width: (toggleFolderSidebar && toggleChatWindow) ? "50vw" : (toggleFolderSidebar && !toggleChatWindow) ? "80vw" : "70vw",
                    transition: "width 0.5s ease-in-out",
                }}
            >

                <Box className="w-full h-[7%] flex items-center justify-between sticky top-0 z-10 backdrop-blur-md" >
                    <Header />
                </Box>

                <Box className="flex-1 grow flex h-[93%]  ">
                    <RequirementMainContainer />
                </Box>


            </Box>


            {/* chat window */}
            {toggleChatWindow && <Box className={` flex overflow-hidden h-full border-l border-gray-300 shadow-md `} bgcolor={theme.palette.primary.layout_col}
                sx={{
                    minWidth: toggleChatWindow ? "30vw" : 0,
                    maxWidth: toggleChatWindow ? "30vw" : 0,
                    transition: "width 0.5s ease-in-out",
                }}
            >
                <ChatWindow />
            </Box>}


            {/* {openStoryPreview && currentUserStory && <StoryPreview />} */}

            {openCreateModuleDialog && <CreateModuleDialog open={openCreateModuleDialog} handleClose={() => setOpenCreateModuleDialog(false)}
                handleConfirm={handleCreateModule} />}

            {openEditModuleDialog && <EditModuleDialog open={openEditModuleDialog} handleClose={() => setOpenEditModuleDialog(false)}
                handleConfirm={handleUpdateModule} />}

            {openDeleteModuleDialog && <DeleteModuleDialog open={openDeleteModuleDialog} handleClose={() => setOpenDeleteModuleDialog(false)}
                handleConfirm={handleDeleteModule} data={currentFolderData?.data} />}



        </Box>
    )
}



function Header({ }) {
    const { activeProject, setActiveProject, activeModule, setActiveModule, activeStory, setActiveStory, toggleChatWindow, setToggleChatWindow, toggleFolderSidebar, setToggleFolderSidebar, isGridViewEnabled, setIsGridViewEnabled, openCreateStoryDialog, setOpenCreateStoryDialog, isShowStoryFilter, setIsShowStoryFilter, openDeleteStoryDialog, setOpenDeleteStoryDialog, selectedUserStoryList, setSelectedUserStoryList } = useApplicationContext();
    const theme = useTheme();
    const navigate = useNavigate();

    // const breadcrumbs = [
    //     <Button variant="outlined" key="1">
    //         {activeProject?.name}
    //     </Button>,
    //     <Button variant="outlined" key="2">
    //         Module
    //     </Button>,
    //     <Button variant="outlined" key="3">
    //         UserStory
    //     </Button>,
    // ];


    const handleOpenCreateStoryDialog = () => {
        setOpenCreateStoryDialog(true);
    }

    const handleHomeClick = () => {
        navigate("/projects");
        setActiveProject(null);
    }

    const handleDeleteClick = () => {
        setOpenDeleteStoryDialog(true);
        console.log(openDeleteStoryDialog, "true")
    }

    console.log(activeModule, "activeModule")

    return (
        <Box className="w-full h-full flex  gap-4 py-1" >

            <SidebarToggleButton />

            <LightTooltip title={`Back to Projects`}>
                <IconButton onClick={handleHomeClick}>
                    <HomeOutlined />
                </IconButton>
            </LightTooltip>

            {!openCreateStoryDialog && <Box className="flex items-center gap-4 py-2 flex-1">


                <Button size="medium" variant="contained" startIcon={<NoteAddOutlinedIcon />} onClick={handleOpenCreateStoryDialog}>Add Story</Button>

                <Divider orientation="vertical" flexItem />

                {/* <LightTooltip title={`View`}>
                    <IconButton  >
                        <FileOpenOutlinedIcon />
                    </IconButton>
                </LightTooltip>

                <LightTooltip title={`Edit`}>
                    <IconButton  >
                        <EditOutlined />
                    </IconButton>
                </LightTooltip> */}

                <LightTooltip title={`Delete`}>
                    <IconButton disabled={selectedUserStoryList?.length === 0} onClick={handleDeleteClick}>
                        <DeleteOutline />
                    </IconButton>
                </LightTooltip>

                <Divider orientation="vertical" flexItem />

                <LightTooltip title={`Upload`}>
                    <IconButton  >
                        <FileUploadOutlinedIcon />
                    </IconButton>
                </LightTooltip>

                <LightTooltip title={`Download`}>
                    <IconButton  >
                        <FileDownloadOutlinedIcon />
                    </IconButton>
                </LightTooltip>

                <LightTooltip title={`${isShowStoryFilter ? "Hide Filter" : "Show Filter"}`}>
                    <IconButton onClick={() => setIsShowStoryFilter(!isShowStoryFilter)} >
                        {isShowStoryFilter ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
                    </IconButton>
                </LightTooltip>

            </Box>}

            {!openCreateStoryDialog && <Box className="flex items-center gap-2 py-2">
                <Box className=" flex items-center  rounded-lg overflow-hidden shadow-md">
                    <LightTooltip title={`Switch to Grid View`}>
                        <IconButton

                            disableRipple sx={{ borderRadius: 0, background: isGridViewEnabled ? theme.palette.common.white : theme.palette.primary.layout_col }}
                            onClick={() => setIsGridViewEnabled(true)}>
                            <GridViewIcon sx={{ fontSize: font.size.fs_3 }} />
                        </IconButton>
                    </LightTooltip>
                    <LightTooltip title={`Switch to List View`}>
                        <IconButton

                            disableRipple
                            sx={{ borderRadius: 0, background: !isGridViewEnabled ? theme.palette.common.white : theme.palette.primary.layout_col }} onClick={() => setIsGridViewEnabled(false)}>
                            <ListIcon sx={{ fontSize: font.size.fs_3 }} />
                        </IconButton>
                    </LightTooltip>
                </Box>
            </Box>}

            <ChatWindowToggleButton sx={{ ml: "auto" }} />
        </Box>
    )
}