import { Add, Close, Create } from "@mui/icons-material"
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, TextField, Typography, useTheme } from "@mui/material"
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { font } from "../../styles/font";


export default function CreateStoryComponent({ open, closeDialog, handleContinue, }) {
    const theme = useTheme();


    const [accetanceCriteriaText, setAccetanceCriteriaText] = useState("");
    const [userStoryData, setUserStoryData] = useState({
        "customId": "",
        "text": "",
        "notes": "",
        "priority": "High",
        "moduleName": "",
        "projectName": "",
        "assignedTo": "",
        "acceptanceCriteria": []
    }
    );

    const handleChange = (event) => {
        setUserStoryData({ ...userStoryData, [event.target.name]: event.target.value });
    }

    const handleAddAcceptanceCriteria = () => {
        setUserStoryData({ ...userStoryData, acceptanceCriteria: [...userStoryData?.acceptanceCriteria, accetanceCriteriaText] });
        setAccetanceCriteriaText("");
    }

    const handleRemoveAcceptanceCriteria = (index) => {
        setUserStoryData({ ...userStoryData, acceptanceCriteria: userStoryData?.acceptanceCriteria.filter((_, i) => i !== index) });
    }

    return (
        <Box className="flex flex-col px-4 w-full h-full z-20 absolute left-0 top-0 "
            bgcolor={theme.palette.primary.layout_col}
        >
            <Box className="flex items-center  pb-4 gap-4">
                <Typography fontSize={font.size.fs_3} className="flex-1">Create Userstory</Typography>

                {/* <Button variant="contained" color="inherit" size="small" startIcon={<Close />} onClick={closeDialog} >Close</Button>
                <Button variant="contained" size="small" startIcon={<Add />} onClick={closeDialog} >Create</Button> */}
            </Box>


            <Box className="flex  gap-4 py-4 flex-1 overflow-y-auto">
                <Box className="flex flex-col flex-1 gap-4 pb-4">
                    <TextField size="small" name="customId" label="Custom ID" fullWidth value={userStoryData?.customId} onChange={handleChange} />
                    <TextField size="small" name="text" label="Title" fullWidth value={userStoryData?.text} onChange={handleChange} />
                    <TextField size="small" name="notes" label="Description" fullWidth multiline minRows={6} maxRows={6} value={userStoryData?.notes} onChange={handleChange} />
                    <Box className="flex flex-col gap-2">
                        <Box className="flex items-center gap-4">
                            <TextField size="small" label="Enter acceptance criteria" fullWidth value={accetanceCriteriaText} onChange={(e) => setAccetanceCriteriaText(e.target.value)} />
                            <Button size="small" variant="outlined" disabled={!accetanceCriteriaText} startIcon={<Add />} onClick={handleAddAcceptanceCriteria} >Add</Button>
                        </Box>

                        <Box className="flex flex-col gap-2">
                            {userStoryData?.acceptanceCriteria?.map((criteria, index) => (
                                <Box key={index} className="flex items-center gap-2 rounded-full px-1 pl-4 py-0" bgcolor={theme.palette.common.white}>
                                    <Typography fontSize={font.size.fs_5} className="flex-1">{criteria}</Typography>
                                    <IconButton size="small" onClick={() => handleRemoveAcceptanceCriteria(index)}><Close /></IconButton>
                                </Box>
                            ))}
                        </Box>
                    </Box>

                    {/* <Box className="flex justify-end gap-2">
                    <Button size="small" variant="outlined" startIcon={<Close />} onClick={closeDialog} sx={{ borderRadius: "28px" }}>Cancel</Button>
                    <Button size="small" variant="contained" startIcon={<Add />} onClick={handleContinue} sx={{ borderRadius: "28px" }}>Continue</Button>
                </Box> */}



                </Box>

                <Box className="w-1/2 flex flex-col gap-4">
                    <Box className="w-full flex gap-4">
                        <Box className="flex flex-col gap-4 w-full">
                            <TextField size="small" label="Category" fullWidth />
                            <TextField size="small" type="email" name="assignedTo" label="Assigned To" fullWidth value={userStoryData?.assignedTo} onChange={handleChange} />
                            <TextField size="small" label="Story Points" fullWidth />
                            <TextField size="small" label="External Points" fullWidth />
                        </Box>

                        <Box className="flex flex-col gap-4 w-full">
                            <TextField size="small" label="MOSCOW" fullWidth />
                            <TextField size="small" label="Review Status" fullWidth />
                            <TextField size="small" label="Verification Owner" fullWidth />
                            <TextField size="small" label="Sub Product" fullWidth />
                        </Box>
                    </Box>

                    <Box className="w-full flex flex-col px-3 py-2 gap-2 border border-gray-400 rounded-lg">
                        <Typography className="opacity-80">Verification Method</Typography>

                        <Box className="flex px-2 gap-2 ">
                            <FormControlLabel control={<Checkbox />} label="Inspection" className="pr-2 select-none rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out" />
                            <FormControlLabel control={<Checkbox />} label="Demo" className="pr-2 select-none rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out" />
                            <FormControlLabel control={<Checkbox />} label="Analysis" className="pr-2 select-none rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out" />
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Testing" className="pr-2 select-none rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out" />
                        </Box>
                    </Box>

                </Box>
            </Box>

            <Box className="border-t border-gray-300 flex items-center justify-center gap-4 py-8">
                <Button variant="contained" color="inherit" size="small" startIcon={<Close />} onClick={closeDialog} >Close</Button>
                <Button variant="contained" size="small" startIcon={<Add />} onClick={() => handleContinue(userStoryData)} >Continue</Button>
            </Box>

        </Box>
    )
}