import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const ApplicationContext = createContext();

export const ApplicationContextProvider = ({ children }) => {

    const pathName = useLocation()?.pathname?.split("/")[1];

    const [toggleChatWindow, setToggleChatWindow] = useState(false); // To toggle the AI chat window
    const [toggleFolderSidebar, setToggleFolderSidebar] = useState(true); // To toggle the folder sidebar
    const [globalSnackbar, setGLobalSnackBar] = useState({
        open: false,
        title: "",
        severity: "",
    }); // To show the global snackbar
    const [globalLoader, setGlobalLoader] = useState(false); // To show the global loader

    const [headerSearchText, setHeaderSearchText] = useState(""); // To store the header search text

    const [profileDetails, setProfileDetails] = useState(null); // To store the profile details of the user



    const [openCreateProjectDrawer, setOpenCreateProjectDrawer] = useState(false); // To open the create project drawer
    const [openEditProjectDrawer, setOpenEditProjectDrawer] = useState(false); // To open the edit project drawer
    const [openDeleteProjectDialog, setOpenDeleteProjectDialog] = useState(false); // To open the delete project dialog

    const [categoryList, setCategoryList] = useState([]); // To store the category list
    const [subCategoryList, setSubCategoryList] = useState([]); // To store the sub category list  
    const [activeProjectCategory, setActiveProjectCategory] = useState(null); // To store the active project category
    const [activeProjectSubcategory, setActiveProjectSubcategory] = useState(null); // To store the active project subcategory
    const [projectList, setProjectList] = useState([]); // To store the project list



    const [openCreateStoryDialog, setOpenCreateStoryDialog] = useState(false); // To open the create story dialog
    const [openEditStoryDialog, setOpenEditStoryDialog] = useState(false); // To open the edit story dialog
    const [openDeleteStoryDialog, setOpenDeleteStoryDialog] = useState(false); // To open the delete story dialog
    const [openStoryPreview, setOpenStoryPreview] = useState(false); // To open the story preview
    const [openStoryConfirmationPreview, setOpenStoryConfirmationPreview] = useState(false); // To open the story confirmation preview - OLD

    const [folderStructureList, setFolderStructureList] = useState([]); // To store the folder structure list
    const [currentFolderData, setCurrentFolderData] = useState(null); // To store the current folder data
    const [isUpdateFolderStructure, setIsUpdateFolderStructure] = useState(false); // Toggle to update the folder structure
    const [isUpdateCurrentFolderData, setIsUpdateCurrentFolderData] = useState(false); // Toggle to update the current folder data

    const [userStoryList, setUserStoryList] = useState([]); // To store the user story list
    const [currentUserStoryData, setCurrentUserStoryData] = useState(null); // To store the current user story
    const [currentUserStory, setCurrentUserStory] = useState(null); // To store the current user story - OLD
    const [selectedUserStoryList, setSelectedUserStoryList] = useState([]); // To store the selected user story



    const [isContentLoading, setIsContentLoading] = useState(false); // To show the content loading
    const [isGridViewEnabled, setIsGridViewEnabled] = useState(false); // To enable the grid view
    const [isShowStoryFilter, setIsShowStoryFilter] = useState(true); // To show the story filter

    const [activeProject, setActiveProject] = useState(null); // To store the active project data
    const [activeModule, setActiveModule] = useState(null); // To store the active module data - OLD
    const [activeStory, setActiveStory] = useState(null); // To store the active story data - OLD

    const handleSnackbar = (message, severity, setLoadingState) => {
        setGLobalSnackBar({
            open: true,
            title: message,
            severity: severity,
        });
        setLoadingState && setLoadingState(false);
    };

    useEffect(() => {
        if (window && localStorage.getItem("profileDetails")) {
            setProfileDetails(JSON.parse(localStorage.getItem("profileDetails")));
        }

        if (window && !localStorage.getItem("profileDetails")) {
            setProfileDetails(null)
        }
    }, [pathName]);

    const values = {
        pathName,
        toggleChatWindow,
        setToggleChatWindow,
        toggleFolderSidebar,
        setToggleFolderSidebar,
        globalSnackbar,
        setGLobalSnackBar,
        globalLoader,
        setGlobalLoader,
        headerSearchText,
        setHeaderSearchText,
        profileDetails,
        setProfileDetails,
        openCreateProjectDrawer,
        setOpenCreateProjectDrawer,
        openEditProjectDrawer,
        setOpenEditProjectDrawer,
        openDeleteProjectDialog,
        setOpenDeleteProjectDialog,
        categoryList,
        setCategoryList,
        subCategoryList,
        setSubCategoryList,
        activeProjectCategory,
        setActiveProjectCategory,
        activeProjectSubcategory,
        setActiveProjectSubcategory,
        projectList,
        setProjectList,
        currentUserStory,
        setCurrentUserStory,
        selectedUserStoryList,
        setSelectedUserStoryList,
        folderStructureList,
        setFolderStructureList,
        userStoryList,
        setUserStoryList,
        currentFolderData,
        setCurrentFolderData,
        isUpdateFolderStructure,
        setIsUpdateFolderStructure,
        isUpdateCurrentFolderData,
        setIsUpdateCurrentFolderData,
        currentUserStoryData,
        setCurrentUserStoryData,
        openCreateStoryDialog,
        setOpenCreateStoryDialog,
        openEditStoryDialog,
        setOpenEditStoryDialog,
        openDeleteStoryDialog,
        setOpenDeleteStoryDialog,
        openStoryPreview,
        setOpenStoryPreview,
        openStoryConfirmationPreview,
        setOpenStoryConfirmationPreview,
        isContentLoading,
        setIsContentLoading,
        isGridViewEnabled,
        setIsGridViewEnabled,
        isShowStoryFilter,
        setIsShowStoryFilter,
        activeProject,
        setActiveProject,
        activeModule,
        setActiveModule,
        activeStory,
        setActiveStory,

        handleSnackbar
    };

    return (
        <ApplicationContext.Provider value={values}>
            {children}
        </ApplicationContext.Provider>
    )
}

export const useApplicationContext = () => useContext(ApplicationContext);