import { AccountCircleOutlined, Add, ArrowRightAlt, Delete, Edit, Folder, FolderOutlined, MoreHoriz } from "@mui/icons-material";
import { Box, Chip, IconButton, Menu, MenuItem, Popover, Tooltip, Typography, useTheme } from "@mui/material";
import { font } from "../../styles/font";
import { blue, brown, green, grey, red } from "@mui/material/colors";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { LightTooltip } from "../styled/CustomComponent";

class STORY_STATUS {
    static DRAFT = "Draft";
    static APPROVED = "Approved";
    static UNDER_DEVELOPMENT = "Under development";
    static CLOSED = "Closed";
}

function MarketplaceCard({ item, handleClick }) {
    const theme = useTheme();

    const options = [
        {
            label: "Duplicate Story",
            icon: <FolderOutlined sx={{ color: theme.palette.primary.main }} />,
            onClick: () => console.log("Duplicate Story"),
        },
        {
            label: "Edit Story",
            icon: <Edit sx={{ color: theme.palette.secondary.main }} />,
            onClick: () => console.log("Edit Story"),
        },
        {
            label: "Delete Story",
            icon: <Delete color="error" />,
            onClick: () => console.log("Delete Story"),
        },
    ];

    function GetStoryStatusColor(story_status) {
        switch (story_status?.toLowerCase()) {
            case STORY_STATUS.DRAFT?.toLowerCase():
                return brown[500];
            case STORY_STATUS.APPROVED?.toLowerCase():
                return green[500];
            case STORY_STATUS.UNDER_DEVELOPMENT?.toLowerCase():
                return blue[500];
            case STORY_STATUS.CLOSED?.toLowerCase():
                return red[500];
            default:
                return grey[500];
        }
    }

    return (
        <Box
            className="bg-white flex flex-col w-full h-full shadow-sm rounded-lg"
            sx={{ transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.02)' } }}
        >
            <Box className="flex items-center gap-2 px-3 py-2 border-b border-gray-200">
                <Typography fontSize={font.size.fs_4} className="truncate flex-1">{item?.name}</Typography>
                <LightTooltip title="Use story" placement="top" arrow>
                    <IconButton size="small" color="primary" sx={{ fontSize: font.size.fs_6 }} onClick={() => console.log("use story")}>
                        <Add />
                    </IconButton>
                </LightTooltip>
            </Box>

            <Box className="flex items-end justify-between px-3 py-3 flex-1 cursor-pointer" onClick={handleClick}>
                <LightTooltip arrow placement="top" title={`Used by 10K users`}>
                    <Box className="flex items-center gap-1">
                        <Typography fontSize={font.size.fs_4}>10K</Typography>
                        <TrendingUpIcon sx={{ color: green[500], fontSize: font.size.fs_4 }} />
                    </Box>
                </LightTooltip>

                <LightTooltip arrow placement="top" title={`You have used this story 11 times`}>
                    <Box className="flex items-center gap-1">
                        <Typography fontSize={font.size.fs_4}>11</Typography>
                        <Folder sx={{ color: theme.palette.primary.main, fontSize: font.size.fs_4 }} />
                    </Box>
                </LightTooltip>
            </Box>
        </Box>
    );
}

export default MarketplaceCard;

