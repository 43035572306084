import { AccountCircleOutlined, ArrowRightAlt, Delete, Edit, FolderOutlined, MoreHoriz } from "@mui/icons-material"
import { Box, Chip, IconButton, Menu, MenuItem, Popover, Typography, useTheme } from "@mui/material"
import { font } from "../../styles/font"
import { blue, brown, green, grey, red } from "@mui/material/colors";
import { useState } from "react";
import { useApplicationContext } from "../../context/ApplicationContext";

class STORY_STATUS {
    static DRAFT = "Draft";
    static APPROVED = "Approved";
    static UNDER_DEVELOPMENT = "Under development";
    static CLOSED = "Closed";
}

const ITEM_HEIGHT = 48;


function StoryCard({ item, handleClick }) {
    const { isGridViewEnabled, setIsGridViewEnabled } = useApplicationContext();
    const theme = useTheme();

    const options = [
        {
            label: "Duplicate Story",
            icon: <FolderOutlined sx={{ color: theme.palette.primary.main }} />,
            onClick: () => console.log("Duplicate Story"),
        },
        {
            label: "Edit Story",
            icon: <Edit sx={{ color: theme.palette.secondary.main }} />,
            onClick: () => console.log("Edit Story"),
        },
        {
            label: "Delete Story",
            icon: <Delete color="error" />,
            onClick: () => console.log("Delete Story"),
        },
    ];

    const [moreAnchorEl, setMoreAchorEl] = useState(null);

    function GetStoryStatusColor(story_status) {
        // console.log(story_status, "story_status")
        switch (story_status?.toLowerCase()) {
            case STORY_STATUS.DRAFT?.toLowerCase():
                return brown[500];
            case STORY_STATUS.APPROVED?.toLowerCase():
                return green[500];
            case STORY_STATUS.UNDER_DEVELOPMENT?.toLowerCase():
                return blue[500];
            case STORY_STATUS.CLOSED?.toLowerCase():
                return red[500];
            default:
                return grey[500];
        }
    }

    const handleOpenMore = (event) => {
        setMoreAchorEl(event.currentTarget);
    };
    const handleCloseMore = () => {
        setMoreAchorEl(null);
    };

    return (
        <Box
            className="bg-white flex flex-col w-full h-full box-content"
            sx={{
                // borderBottom: `4px solid ${GetStoryStatusColor(item?.status?.toLowerCase())}`,
                // borderStyle: "inset",
                // "@media (max-width: 600px)": {
                //     width: "100%",
                // },
            }}
            onClick={handleClick}
        >

            {/* upper container */}
            <Box className="flex items-center gap-2 px-2 py-1 border-b">
                {!isGridViewEnabled && <Box className="flex items-center gap-1"  >
                    <Typography className="font-bold">#</Typography>
                    <Typography className="text-sm">{item?.id}</Typography>
                </Box>}
                <Typography fontSize={font.size.fs_4} className=" line-clamp-3 flex-1">{item?.name}</Typography>
                <IconButton size="small" sx={{ fontSize: font.size.fs_6 }} onClick={handleOpenMore}>
                    <MoreHoriz />
                </IconButton>
                {/* <Typography variant="body2" className="text-sm line-clamp-1">{item?.description}</Typography> */}
            </Box>


            {/* bottom container */}
            {isGridViewEnabled && <Box className="flex items-end justify-between  px-2 py-2 flex-1 cursor-pointer " >

                <Box className="flex items-center gap-2">
                    <Box className="flex items-center gap-1"  >
                        <Typography className="font-bold">#</Typography>
                        <Typography className="text-sm">{item?.id}</Typography>
                    </Box>

                    {/* <Typography variant="subtitle2" className={` text-white px-2 py-0 rounded-full line-clamp-1 whitespace-nowrap`} sx={{ fontSize: "12px", background: GetStoryStatusColor(item?.status?.toLowerCase()) }}>{item?.status}</Typography> */}

                    {/* <Chip
                        variant="outlined"
                        size="small"
                        label={item?.status}
                        className="transition-all ease-in-out duration-300 cursor-pointer line-clamp-1"
                        sx={{ color: GetStoryStatusColor(item?.status?.toLowerCase()), }}
                    /> */}
                </Box>

                <Box className="flex items-center gap-2">
                    <Box className="flex items-center justify-end gap-1"  >
                        <AccountCircleOutlined />
                    </Box>
                </Box>
            </Box>}

            <Popover
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={moreAnchorEl}
                open={Boolean(moreAnchorEl)}
                onClose={handleCloseMore}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={handleCloseMore} className="flex items-center gap-2">
                        {option?.icon}
                        {option?.label}
                    </MenuItem>
                ))}
            </Popover>
        </Box>
    )
}

export default StoryCard

