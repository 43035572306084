import { createContext, useState } from "react";
import { themePalette } from "../styles/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

export const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
    const [getTheme, setTheme] = useState(themePalette);

    const values = { getTheme, setTheme };

    return (
        <ThemeContext.Provider value={values}>
            <ThemeProvider theme={themePalette}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}